import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { routeProviderClassificationsReducer } from './reducer';

type MakeGetRouteProviderClassificationsData = routeProviderClassificationsReducer['routeProviderClassifications']['data'];

const getRouteProviderClassifications = (state: routeProviderClassificationsReducer) => state.routeProviderClassifications;

export const makeGetRouteProviderClassificationsData = () =>
  createSelector<
    routeProviderClassificationsReducer,
    routeProviderClassificationsReducer['routeProviderClassifications'],
    MakeGetRouteProviderClassificationsData
  >(getRouteProviderClassifications, ({ data }) => data);

export const makeGetRouteProviderClassificationsStatus = () =>
  createSelector<routeProviderClassificationsReducer, routeProviderClassificationsReducer['routeProviderClassifications'], ReturnType<typeof getDataStatus>>(
    getRouteProviderClassifications,
    getDataStatus
  );

import { string, object, number, mixed, array, AnySchema, ValidationError } from 'yup';

import { i18n } from '@/core/services/i18n';
import { CustomFile } from '../components/form-controls-deprecated';
import { FileSize } from '../components/form-controls-deprecated/file-input/models';

const MIN_LATITUDE = -90;
const MAX_LATITUDE = 90;
const MIN_LONGITUDE = -180;
const MAX_LONGITUDE = 180;

export const VALIDATION_MESSAGE = {
  REQUIRED: 'validationMessage.required',
  TITLE_REQUIRED: 'validationMessage.titleRequired',
  ARRAY_REQUIRED: 'validationMessage.arrayRequired',
  STRING_TO_SHORT: 'validationMessage.tooShort',
  STRING_TO_LONG: 'validationMessage.tooLong',
  EMAIL: 'validationMessage.wrongEmail',
  SELECT_AT_LEAST_ONE_OPTION: 'validationMessage.selectAtLeastOneOption',
  SELECT_ONE_OPTION: 'validationMessage.selectOneOption',
  JSON_FORMAT: 'validationMessage.json',
  MIN_LATITUDE: 'validationMessage.minLatitude',
  MAX_LATITUDE: 'validationMessage.maxLatitude',
  MIN_LONGITUDE: 'validationMessage.minLongitude',
  MAX_LONGITUDE: 'validationMessage.maxLongitude',
  MIN: 'validationMessage.min',
  MAX: 'validationMessage.max',
  POSITIVE_NUMBER: 'validationMessage.positiveValues',
  REGEX: 'validationMessage.regExp',
  FILE_TOO_LARGE: 'validationMessage.fileTooLarge',
  UNSUPPORTED_FORMAT: 'validationMessage.unsupportedFormat',
  WEBSITE_FORMAT: 'validationMessage.websiteFormat',
  ISBN_FORMAT: 'validationMessage.isbnFormat',
  ARRAY_ONLY_ONE: 'validationMessage.onlyOneSelected',
  INVALID_DATE_FORMAT: 'validationMessage.invalidDateFormat',
  MIN_DATE: 'validationMessage.minDate',
  MAX_DATE: 'validationMessage.maxDate',
};

export function isValidationError(err: unknown): err is ValidationError {
  return typeof err === 'object' && err !== null && 'errors' in err && 'message' in err;
}

export const STRING_REQUIRED = string().required(() => i18n.t(VALIDATION_MESSAGE.REQUIRED));

export const STRING_LENGTH = (min: number, max: number) =>
  string()
    .trim()
    .required(() => i18n.t(VALIDATION_MESSAGE.REQUIRED))
    .min(min, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_SHORT, { total: min }))
    .max(max, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_LONG, { total: max }));

export const COMMON_STRING_LENGTH = STRING_LENGTH(1, 255);

export const STRING_LENGTH_NOT_REQUIRED = (min: number, max: number) =>
  string()
    .trim()
    .min(min, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_SHORT, { total: min }))
    .max(max, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_LONG, { total: max }));

export const COMMON_STRING_LENGTH_NOT_REQUIRED = STRING_LENGTH_NOT_REQUIRED(0, 255);

const EMAIL_LOCAL_PART_MAX_LENGTH = 64;
const EMAIL_DOMAIN_MAX_LENGTH = 255;
const EMAIL_SUBDOMAIN_MAX_LENGTH = 63;
export const EMAIL_MAX_LENGTH = 100;

type EmailValidatorMessage = {
  required?: string;
  format?: string;
};
export const EMAIL_VALIDATOR = (message?: EmailValidatorMessage) =>
  string()
    .required(() => i18n.t(message?.required || VALIDATION_MESSAGE.REQUIRED))
    .email(() => i18n.t(message?.format || VALIDATION_MESSAGE.EMAIL))
    .max(EMAIL_MAX_LENGTH, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_LONG, { total: EMAIL_MAX_LENGTH }))
    .test('Match required format', i18n.t(message?.format || VALIDATION_MESSAGE.EMAIL), email => {
      const parts = email?.split('@');
      const localPart = parts?.[0];
      if (!localPart?.length || localPart.length > EMAIL_LOCAL_PART_MAX_LENGTH) return false;

      const domain = parts?.[1];
      if (!domain?.length || domain.length > EMAIL_DOMAIN_MAX_LENGTH) return false;

      const subdomains = domain.split('.');
      return !subdomains?.some(subdomain => subdomain.length > EMAIL_SUBDOMAIN_MAX_LENGTH);
    });

export const LATITUDE_VALIDATOR = string()
  .test('required', i18n.t(VALIDATION_MESSAGE.REQUIRED), value => !!value)
  .test('min latitude', i18n.t(VALIDATION_MESSAGE.MIN_LATITUDE), value => Number(value) >= MIN_LATITUDE)
  .test('max latitude', i18n.t(VALIDATION_MESSAGE.MAX_LATITUDE), value => Number(value) <= MAX_LATITUDE);

export const LONGITUDE_VALIDATOR = string()
  .test('required', i18n.t(VALIDATION_MESSAGE.REQUIRED), value => !!value)
  .test('min longitude', i18n.t(VALIDATION_MESSAGE.MIN_LONGITUDE), value => Number(value) >= MIN_LONGITUDE)
  .test('max longitude', i18n.t(VALIDATION_MESSAGE.MAX_LONGITUDE), value => Number(value) <= MAX_LONGITUDE);

export const NUMBER_RANGE_VALIDATOR = (min: number, max: number) =>
  number()
    .required(() => i18n.t(VALIDATION_MESSAGE.REQUIRED))
    .min(min, () => i18n.t(VALIDATION_MESSAGE.MIN, { min }))
    .max(max, () => i18n.t(VALIDATION_MESSAGE.MAX, { max }));

export const GREATER_THAN_ZERO = number()
  .required(() => i18n.t(VALIDATION_MESSAGE.REQUIRED))
  .moreThan(0, () => i18n.t(VALIDATION_MESSAGE.POSITIVE_NUMBER));

export const GREATER_THAN_ZERO_NOT_REQUIRED = number().moreThan(0, () => i18n.t(VALIDATION_MESSAGE.POSITIVE_NUMBER));

const checkFileSize = (maxSize: FileSize, file: File | undefined): boolean => {
  if (file !== undefined && file.size !== undefined) {
    return file.size <= maxSize.Bytes;
  }

  return true;
};

const checkFileExtension = (supportedFormats: string[], file: File | CustomFile | undefined): boolean => {
  if (file !== undefined && file.name !== undefined && file instanceof File) {
    const fileName: string = file.name;
    const fileExtension: string | undefined = fileName ? fileName.split('.').pop() : '';

    if (fileExtension !== undefined) {
      return supportedFormats.includes(fileExtension.toLowerCase());
    }
  }

  return true;
};

export const FILE_VALIDATOR = (maxSize: any, supportedFormats: string[]) =>
  mixed()
    .test('fileRequired', i18n.t(VALIDATION_MESSAGE.REQUIRED), value => (value ? value[0] : undefined))
    .test('fileType', i18n.t(VALIDATION_MESSAGE.UNSUPPORTED_FORMAT), value => checkFileExtension(supportedFormats, value ? value[0] : undefined))
    .test('fileSize', i18n.t(VALIDATION_MESSAGE.FILE_TOO_LARGE), value => checkFileSize(maxSize, value ? value[0] : undefined));

export const FILE_VALIDATOR_NOT_REQUIRED = (maxSize: FileSize, supportedFormats: string[]) =>
  mixed()
    .test('fileType', i18n.t(VALIDATION_MESSAGE.UNSUPPORTED_FORMAT), value => checkFileExtension(supportedFormats, value ? value[0] : undefined))
    .test('fileSize', i18n.t(VALIDATION_MESSAGE.FILE_TOO_LARGE), value => checkFileSize(maxSize, value ? value[0] : undefined));

export const UNIT_INPUT_VALIDATOR = (inputValidator: AnySchema, selectValidator: AnySchema) =>
  object().shape({
    inputValue: inputValidator,
    selectValue: selectValidator,
  });

export const AT_LEAST_ONE_VALUE_SELECT_VALIDATOR = string().required(VALIDATION_MESSAGE.SELECT_AT_LEAST_ONE_OPTION);
export const SINGLE_VALUE_SELECT_VALIDATOR = string().required(VALIDATION_MESSAGE.SELECT_ONE_OPTION);

export const REGEX_VALIDATOR = (regExp: RegExp, min: number, max: number) =>
  string()
    .trim()
    .required(() => i18n.t(VALIDATION_MESSAGE.REQUIRED))
    .matches(regExp, VALIDATION_MESSAGE.REGEX)
    .min(min, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_SHORT, { total: min }))
    .max(max, () => i18n.t(VALIDATION_MESSAGE.STRING_TO_LONG, { total: max }));

export const WEBSITE_VALIDATOR = () =>
  string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, VALIDATION_MESSAGE.WEBSITE_FORMAT);

export const ARRAY_AT_LEAST_ONE = array()
  .required()
  .min(1, () => i18n.t(VALIDATION_MESSAGE.ARRAY_REQUIRED));

export const ARRAY_ONLY_ONE = array()
  .required()
  .min(1, () => i18n.t(VALIDATION_MESSAGE.REQUIRED))
  .max(1, () => i18n.t(VALIDATION_MESSAGE.ARRAY_ONLY_ONE));

export const ACCORDION_VALIDATOR = object().shape({
  title: string().required(() => i18n.t(VALIDATION_MESSAGE.TITLE_REQUIRED)),
  body: ARRAY_AT_LEAST_ONE,
});

export const ISBN_VALIDATOR = () =>
  string()
    .trim()
    .required(() => i18n.t(VALIDATION_MESSAGE.REQUIRED))
    .matches(/^(\d{13})?$/, VALIDATION_MESSAGE.ISBN_FORMAT);

export const OBJECT_REQUIRED = object().nullable().required(i18n.t('validationMessage.required'));

export const AUTOCOMPLETE_OPTION_REQUIRED = (message: string = VALIDATION_MESSAGE.REQUIRED) =>
  object()
    .nullable()
    .required(() => i18n.t(message));

import { createReducer } from 'typesafe-actions';

import { type ProviderClassificationsDictionaryVM } from '@/models/provider-classifications';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { type FeatureStateType } from '../helpers';
import { providerClassificationsActions } from './actions';

type providerClassificationsState = DictionaryState<ProviderClassificationsDictionaryVM>;

const INITIAL_STATE: providerClassificationsState = getDictionaryInitialState<ProviderClassificationsDictionaryVM>();

const reducer = createReducer<providerClassificationsState, providerClassificationsActions>(INITIAL_STATE)
  .handleAction(providerClassificationsActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(providerClassificationsActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(providerClassificationsActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(providerClassificationsActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(providerClassificationsActions.failure, (state, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const providerClassificationsReducer = { providerClassifications: reducer };
export type providerClassificationsReducer = FeatureStateType<typeof providerClassificationsReducer>;

import React, { FC, KeyboardEventHandler, useCallback } from 'react';
import cn from 'classnames';

import { type AutocompleteRenderInputParams, TextField } from '@mui/material';
import { useStyles } from '@/styles/hooks';
import { autocompleteInputStyle } from './styles';

const NO_OPTION_PLACEHOLDER = '-----';

type Props = AutocompleteRenderInputParams & {
  isButtonVisible?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  noOptionPlaceholder?: boolean;
};

export const AutocompleteInput: FC<Props> = ({ isButtonVisible, isDisabled, placeholder, noOptionPlaceholder, ...props }) => {
  const { styles } = useStyles(autocompleteInputStyle);

  const handleKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  return (
    <div ref={props.InputProps.ref} css={styles}>
      <TextField
        {...props}
        className={cn([
          'autocomplete-input',
          { 'autocomplete-input--disabled': isDisabled },
          { 'autocomplete-input--with-button': isButtonVisible },
          { 'autocomplete-input--no-option-placeholder': noOptionPlaceholder },
        ])}
        onKeyDown={handleKeyPress}
        placeholder={noOptionPlaceholder ? NO_OPTION_PLACEHOLDER : placeholder}
        disabled={isDisabled}
        variant='outlined'
        size='small'
      />
    </div>
  );
};

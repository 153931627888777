import { createReducer } from 'typesafe-actions';

import { UserDetailsVM } from '@/features/accounts/models/users';
import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../../helpers';
import { usersActions } from '../actions';

export type userPopoverState = FetchState<UserDetailsVM>;

const INITIAL_STATE: userPopoverState = getFetchInitialState<UserDetailsVM>();

const reducer = createReducer<userPopoverState, usersActions>(INITIAL_STATE)
  .handleAction(usersActions.popover.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(usersActions.popover.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(usersActions.popover.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(usersActions.clearPopoverUser, () => ({ ...INITIAL_STATE }));

export const userPopoverReducer = { popover: reducer };
export type userPopoverReducer = FeatureStateType<typeof userPopoverReducer>;

import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useStyles } from '@/styles/hooks';

import { TOOLBAR_INFO } from '@/common/utils/element-ids';
import { links } from '../../constants';
import { NavigationElementGroup } from './components/navigation-element-group';
import { navigationElementStyles } from './styles';

type Props = {
  elements: typeof links;
};

export const NavigationElementsBar: FC<Props> = ({ elements }) => {
  const { styles } = useStyles(navigationElementStyles);
  const breadcrumpUrls = useMemo(() => elements.map(group => group.url), [elements]);

  const match = useRouteMatch({ path: breadcrumpUrls });

  return (
    <div css={styles}>
      {elements.map(group => {
        if (group.url === match?.url) {
          return <NavigationElementGroup key={group.label} data={group} />;
        }
        return null;
      })}
      <div id={TOOLBAR_INFO} />
    </div>
  );
};

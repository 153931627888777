import React, { KeyboardEvent, SyntheticEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/styles/hooks';
import { Datepicker, DatepickerRef, Input, Select, SelectOption } from '@/common/components/form-controls-deprecated';
import { getFirstDayOfMonth, getLastDayOfMonth } from './functions';
import {
  CURRENT_MONTH_END,
  CURRENT_MONTH_START,
  CURRENT_QUARTER_END,
  CURRENT_QUARTER_START,
  CURRENT_YEAR_END,
  CURRENT_YEAR_START,
  datePeriodOptions,
  dayModeDateFormat,
  dayModePlaceholder,
  LAST_MONTH_END,
  LAST_MONTH_START,
  LAST_QUARTER_END,
  LAST_QUARTER_START,
  LAST_YEAR_END,
  LAST_YEAR_START,
  monthModeDateFormat,
  monthModePlaceholder,
  Period,
} from './constants';
import { dateFilterStyles } from './styles';

export type DateValue = Dayjs | null | undefined;
export type DateBoundaries<T = DateValue> = { start?: T; end?: T };

export type DateFilterRef = {
  clearDates: () => void;
};

export type DateFilterProps = {
  monthMode?: boolean;
  disabled?: boolean;
  value: DateBoundaries<DateValue>;
  onChange: (dateFrom: Date | null, dateTo: Date | null) => void;
  isClearable?: boolean;
  defaultDates?: { dateFrom: Date; dateTo: Date };
  maxSearchDate?: Date;
};

export const DateFilter = forwardRef<DateFilterRef, DateFilterProps>(
  ({ monthMode, disabled, defaultDates, onChange, value, maxSearchDate, isClearable = true }, ref) => {
    const [startDate, setStartDate] = useState<Date | null>(value.start?.toDate() || null);
    const [endDate, setEndDate] = useState<Date | null>(value.end?.toDate() || null);
    const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);
    const [isDateToCalendarOpen, setIsDateToCalendarOpen] = useState(false);
    const [isDateFromCalendarOpen, setIsDateFromCalendarOpen] = useState(false);
    const startDatePickerRef = useRef<DatepickerRef>(null);
    const endDatePickerRef = useRef<DatepickerRef>(null);

    useImperativeHandle(ref, () => ({
      clearDates: () => {
        startDatePickerRef.current?.forceClearInput();
        endDatePickerRef.current?.forceClearInput();
      },
    }));

    const handleDateToInputClick = () => {
      setIsDateToCalendarOpen(!isDateToCalendarOpen);
    };

    const handleDateFromInputClick = () => {
      setIsDateFromCalendarOpen(!isDateFromCalendarOpen);
    };

    const [t] = useTranslation();
    const { styles } = useStyles(dateFilterStyles);

    const detectDatePeriod = useCallback(
      (dateFrom: Date | null, dateTo: Date | null) => {
        if (dateFrom?.toDateString() === CURRENT_MONTH_START.toDateString() && dateTo?.toDateString() === CURRENT_MONTH_END.toDateString()) {
          setSelectedPeriod('CURRENT MONTH');
          return;
        }
        if (dateFrom?.toDateString() === CURRENT_QUARTER_START.toDateString() && dateTo?.toDateString() === CURRENT_QUARTER_END.toDateString()) {
          setSelectedPeriod('CURRENT QUARTER');
          return;
        }
        if (dateFrom?.toDateString() === CURRENT_YEAR_START.toDateString() && dateTo?.toDateString() === CURRENT_YEAR_END.toDateString()) {
          setSelectedPeriod('CURRENT YEAR');
          return;
        }
        if (dateFrom?.toDateString() === LAST_MONTH_START.toDateString() && dateTo?.toDateString() === LAST_MONTH_END.toDateString()) {
          setSelectedPeriod('LAST MONTH');
          return;
        }
        if (dateFrom?.toDateString() === LAST_QUARTER_START.toDateString() && dateTo?.toDateString() === LAST_QUARTER_END.toDateString()) {
          setSelectedPeriod('LAST QUARTER');
          return;
        }
        if (dateFrom?.toDateString() === LAST_YEAR_START.toDateString() && dateTo?.toDateString() === LAST_YEAR_END.toDateString()) {
          setSelectedPeriod('LAST YEAR');
          return;
        }

        setSelectedPeriod('CUSTOM PERIOD');
      },
      [onChange]
    );

    const handleDateChange = useCallback(
      (dateFrom: Date | null, dateTo: Date | null) => {
        setStartDate(dateFrom);
        setEndDate(dateTo);
        setIsDateFromCalendarOpen(false);
        setIsDateToCalendarOpen(false);
        onChange(dateFrom, dateTo);
      },
      [onChange]
    );

    const ignoreOnEnterPressIfCalendarIsClosed = (event: SyntheticEvent, isCalendarOpen: boolean) => {
      if (event?.type === 'keydown') {
        const keyEvent = event as KeyboardEvent<HTMLInputElement>;
        if (keyEvent.key === 'Enter' && !isCalendarOpen) {
          return true;
        }
      }
    };

    const handleStartDateChange = useCallback(
      (date: Date | null, event: SyntheticEvent) => {
        if (!ignoreOnEnterPressIfCalendarIsClosed(event, isDateFromCalendarOpen)) {
          const formattedDate = monthMode && date ? getFirstDayOfMonth(date) : date;
          detectDatePeriod(formattedDate, endDate);
          handleDateChange(formattedDate, endDate);
        }
      },
      [endDate, detectDatePeriod, isDateFromCalendarOpen, handleDateChange, monthMode]
    );

    const handleEndDateChange = useCallback(
      (date: Date | null, event: SyntheticEvent) => {
        if (!ignoreOnEnterPressIfCalendarIsClosed(event, isDateToCalendarOpen)) {
          const formattedDate = monthMode && date ? getLastDayOfMonth(date) : date;
          detectDatePeriod(startDate, formattedDate);
          handleDateChange(startDate, formattedDate);
        }
      },
      [startDate, detectDatePeriod, isDateToCalendarOpen, handleDateChange, monthMode]
    );

    const handleSelectChange = useCallback(
      (option: SelectOption) => {
        switch (option.value) {
          case Period['CURRENT MONTH']: {
            handleDateChange(CURRENT_MONTH_START, CURRENT_MONTH_END);
            setSelectedPeriod('CURRENT MONTH');
            return;
          }
          case Period['CURRENT QUARTER']: {
            handleDateChange(CURRENT_QUARTER_START, CURRENT_QUARTER_END);
            setSelectedPeriod('CURRENT QUARTER');
            return;
          }
          case Period['CURRENT YEAR']: {
            handleDateChange(CURRENT_YEAR_START, CURRENT_YEAR_END);
            setSelectedPeriod('CURRENT YEAR');
            return;
          }
          case Period['LAST MONTH']: {
            handleDateChange(LAST_MONTH_START, LAST_MONTH_END);
            setSelectedPeriod('LAST MONTH');
            return;
          }
          case Period['LAST QUARTER']: {
            handleDateChange(LAST_QUARTER_START, LAST_QUARTER_END);
            setSelectedPeriod('LAST QUARTER');
            return;
          }
          case Period['LAST YEAR']: {
            handleDateChange(LAST_YEAR_START, LAST_YEAR_END);
            setSelectedPeriod('LAST YEAR');
            return;
          }
          case Period['CUSTOM PERIOD']: {
            defaultDates ? handleDateChange(defaultDates.dateFrom, defaultDates.dateTo) : handleDateChange(null, null);
            setSelectedPeriod('CUSTOM PERIOD');
            return;
          }
        }
      },
      [defaultDates, handleDateChange]
    );

    useEffect(() => {
      setStartDate(value.start?.toDate() || null);
      setEndDate(value.end?.toDate() || null);
      detectDatePeriod(value.start?.toDate() || null, value.end?.toDate() || null);
    }, [value]);

    return (
      <div css={styles}>
        <div>
          <Select options={datePeriodOptions} onChange={handleSelectChange} value={selectedPeriod || undefined} disabled={disabled} />
        </div>
        <div className='date-range-input-wrapper'>
          <div className='date-range-input-row'>
            <div className='date-range-input-label'> {t('label.from')}:</div>
            <div className='date-range-input'>
              <Datepicker
                ref={startDatePickerRef}
                disabled={disabled}
                selected={startDate}
                onChange={handleStartDateChange}
                maxDate={endDate || maxSearchDate}
                dateFormat={monthMode ? monthModeDateFormat : dayModeDateFormat}
                customInput={<Input />}
                isClearable={isClearable}
                monthMode={monthMode}
                placeholder={monthMode ? monthModePlaceholder : dayModePlaceholder}
                open={isDateFromCalendarOpen}
                onInputClick={handleDateFromInputClick}
                onClickOutside={() => setIsDateFromCalendarOpen(false)}
              />
            </div>
          </div>

          <div className='date-range-input-row'>
            <div className='date-range-input-label'> {t('label.to')}:</div>
            <div className='date-range-input'>
              <Datepicker
                ref={endDatePickerRef}
                disabled={disabled}
                selected={endDate}
                onChange={handleEndDateChange}
                minDate={startDate}
                dateFormat={monthMode ? monthModeDateFormat : dayModeDateFormat}
                customInput={<Input />}
                isClearable={isClearable}
                monthMode={monthMode}
                placeholder={monthMode ? monthModePlaceholder : dayModePlaceholder}
                open={isDateToCalendarOpen}
                onInputClick={handleDateToInputClick}
                onClickOutside={() => setIsDateToCalendarOpen(false)}
                maxDate={maxSearchDate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { resetMiddleware } from './middleware';
import { authenticationActions, authenticationReducer, authenticationEpics } from './authentication';
import { interfaceActions, interfaceReducer } from './interface';
import { organisationsReducer, organisationsEpics, organisationsActions } from './organisations';
import { rolesReducer, rolesEpics, rolesActions } from './roles';
import { usersEpics, usersActions, usersReducer } from './users';
import { entitlementsEpics, entitlementsReducer, entitlementsActions } from './entitlements';
import { routesReducer, routesEpics, routesActions } from './routes';
import { difficultiesReducer, difficultiesEpics, difficultiesActions } from './difficulties';
import { activitiesReducer, activitiesEpics, activitiesActions } from './activities';
import { toursActions, toursEpics, toursReducer } from './tours';
import { dataCenterSwitcherActions, dataCenterSwitcherEpics, regionsReducer } from './data-center-regions';
import { moderationActions, moderationEpics, moderationReducer } from './moderation';
import { contextTypesActions, contextTypesEpics, contextTypesReducer } from './context-types';
import { reasonsActions, reasonsEpics, reasonsReducer } from './reasons';
import { statusesActions, statusesEpics, statusesReducer } from './statuses';
import { contentTypesActions, contentTypesEpics, contentTypesReducer } from './content-types';
import { applicationsActions, applicationsEpics, applicationsReducer } from './applications';
import { voucherBatchActions, voucherBatchEpics, voucherBatchReducer } from './voucher-batches';
import { contentProvidedByUserActions, contentProvidedByUserEpics, contentProvidedByUserReducer } from './content-provided-by-user';
import { communityStoriesActions, communityStoriesEpics, communityStoriesReducer } from './community-stories';
import { commonDictionariesActions, commonDictionariesEpics, commonDictionariesReducer } from './common-dictionaries';
import { smartMapsActions, smartMapsEpics, smartMapsReducer } from './smartmaps';
import { tourAnalyticsActions, tourAnalyticsEpic, tourAnalyticsReducer } from './tour-analytics';
import { routeAnalyticsActions, routeAnalyticsEpic, routeAnalyticsReducer } from './route-analytics';
import { thingsToDoActions, thingsToDoEpics, thingsToDoReducer } from './things-to-do';
import { thingsToDoTagsActions, thingsToDoTagsEpics, thingsToDoTagsReducer } from './things-to-do-tags';
import { mapAttributionActions, mapAttributionReducer, mapAttributionEpics } from './map-attribution';
import { invitationsActions, invitationsEpics, invitationsReducer } from './invitations';
import { reviewsActions, reviewsEpics, reviewsReducer } from './reviews';
import { visibilityActions, visibilityEpics, visibilityReducer } from './visibility';
import { galleryActions, galleryEpics, galleryReducer } from './gallery';
import { routeProviderClassificationsActions, routeProviderClassificationsReducer, routeProviderClassificationsEpics } from './route-provider-classification';
import { providerClassificationsActions, providerClassificationsEpics, providerClassificationsReducer } from './provider-classifications';

const INITIAL_STATE = {};

export type rootActions =
  | authenticationActions
  | interfaceActions
  | usersActions
  | rolesActions
  | difficultiesActions
  | visibilityActions
  | activitiesActions
  | organisationsActions
  | entitlementsActions
  | routesActions
  | toursActions
  | dataCenterSwitcherActions
  | moderationActions
  | statusesActions
  | reasonsActions
  | contextTypesActions
  | contentTypesActions
  | applicationsActions
  | voucherBatchActions
  | contentProvidedByUserActions
  | communityStoriesActions
  | commonDictionariesActions
  | tourAnalyticsActions
  | routeAnalyticsActions
  | thingsToDoActions
  | thingsToDoTagsActions
  | smartMapsActions
  | mapAttributionActions
  | reviewsActions
  | invitationsActions
  | galleryActions
  | routeProviderClassificationsActions
  | providerClassificationsActions;

export type rootState = authenticationReducer &
  interfaceReducer &
  usersReducer &
  rolesReducer &
  difficultiesReducer &
  visibilityReducer &
  activitiesReducer &
  organisationsReducer &
  entitlementsReducer &
  routesReducer &
  toursReducer &
  regionsReducer &
  moderationReducer &
  statusesReducer &
  reasonsReducer &
  contentTypesReducer &
  contextTypesReducer &
  applicationsReducer &
  voucherBatchReducer &
  contentProvidedByUserReducer &
  communityStoriesReducer &
  commonDictionariesReducer &
  smartMapsReducer &
  tourAnalyticsReducer &
  routeAnalyticsReducer &
  thingsToDoReducer &
  thingsToDoTagsReducer &
  mapAttributionReducer &
  reviewsReducer &
  invitationsReducer &
  galleryReducer &
  routeProviderClassificationsReducer &
  providerClassificationsReducer;

export const history = createBrowserHistory();

const makeStore = () => {
  const epicMiddleware = createEpicMiddleware<rootActions, rootActions, any>();
  const reduxMiddleware = composeWithDevTools(applyMiddleware(epicMiddleware), resetMiddleware());
  const rootEpic = combineEpics(
    ...authenticationEpics,
    ...usersEpics,
    ...organisationsEpics,
    ...rolesEpics,
    ...difficultiesEpics,
    ...visibilityEpics,
    ...activitiesEpics,
    ...entitlementsEpics,
    ...routesEpics,
    ...toursEpics,
    ...dataCenterSwitcherEpics,
    ...moderationEpics,
    ...statusesEpics,
    ...reasonsEpics,
    ...contentTypesEpics,
    ...contextTypesEpics,
    ...applicationsEpics,
    ...voucherBatchEpics,
    ...smartMapsEpics,
    ...commonDictionariesEpics,
    ...contentProvidedByUserEpics,
    ...communityStoriesEpics,
    ...tourAnalyticsEpic,
    ...routeAnalyticsEpic,
    ...thingsToDoEpics,
    ...thingsToDoTagsEpics,
    ...mapAttributionEpics,
    ...reviewsEpics,
    ...invitationsEpics,
    ...galleryEpics,
    ...routeProviderClassificationsEpics,
    ...providerClassificationsEpics
  );

  const rootReducer = combineReducers({
    ...authenticationReducer,
    ...interfaceReducer,
    ...organisationsReducer,
    ...rolesReducer,
    ...difficultiesReducer,
    ...visibilityReducer,
    ...activitiesReducer,
    ...usersReducer,
    ...entitlementsReducer,
    ...routesReducer,
    ...toursReducer,
    ...regionsReducer,
    ...moderationReducer,
    ...statusesReducer,
    ...reasonsReducer,
    ...contentTypesReducer,
    ...contextTypesReducer,
    ...applicationsReducer,
    ...voucherBatchReducer,
    ...contentProvidedByUserReducer,
    ...commonDictionariesReducer,
    ...smartMapsReducer,
    ...communityStoriesReducer,
    ...tourAnalyticsReducer,
    ...routeAnalyticsReducer,
    ...thingsToDoReducer,
    ...thingsToDoTagsReducer,
    ...mapAttributionReducer,
    ...reviewsReducer,
    ...invitationsReducer,
    ...galleryReducer,
    ...routeProviderClassificationsReducer,
    ...providerClassificationsReducer,
    router: connectRouter(history),
  });

  const store = createStore(rootReducer, INITIAL_STATE, reduxMiddleware);
  epicMiddleware.run(rootEpic);
  return store;
};

export const store = makeStore();

import { createSelector } from 'reselect';

import {
  type UserListVM,
  type UserListSearchByOptionListVM,
  type UsersAppsListVM,
  type AppDictionaryVM,
  type PlatformDictionaryVM,
} from '@/features/accounts/models/users';
import { type HistoryChangesListVM } from '@/models/history-changes';
import { getDataStatus } from '@/common/utils';
import { usersReducer } from './reducers';

const getUsers = (state: usersReducer) => state.users.list;
const getHistoryChanges = (state: usersReducer) => state.users.historyChanges;
const getUserDetails = (state: usersReducer) => state.users.details;
const getPopoverUser = (state: usersReducer) => state.users.popover;
const getSearchByOptions = (state: usersReducer) => state.users.searchByOptions;
const getAppDictionary = (state: usersReducer) => state.users.appDictionary;
const getPlatformDictionary = (state: usersReducer) => state.users.platformDictionary;
const getUsersApps = (state: usersReducer) => state.users.usersApps;

type UserDetails = usersReducer['users']['details'];
export type MakeGetUserDetailsData = UserDetails['data'];

export const makeGetUserDetailsData = () => createSelector<usersReducer, UserDetails, MakeGetUserDetailsData>(getUserDetails, ({ data }) => data);

export const makeGetUserDetailsStatus = () => createSelector<usersReducer, UserDetails, ReturnType<typeof getDataStatus>>(getUserDetails, getDataStatus);

export const makeGetPopoverUserData = () => createSelector<usersReducer, UserDetails, MakeGetUserDetailsData>(getPopoverUser, ({ data }) => data);

export const makeGetPopoverUserStatus = () => createSelector<usersReducer, UserDetails, ReturnType<typeof getDataStatus>>(getPopoverUser, getDataStatus);

type UserList = usersReducer['users']['list'];
type HistoryChanges = usersReducer['users']['historyChanges'];
type MakeGetUserListNextLink = UserListVM['nextBatchLink'] | undefined;
type MakeGetUserList = UserListVM['users'];
type MakeGetUserListFilters = UserList['params'];
type MakeGetUserListError = UserList['error'];

export const makeGetUserList = () => createSelector<usersReducer, UserList, MakeGetUserList>(getUsers, ({ data }) => (data === undefined ? [] : data.users));

export const makeGetUserListFilters = () => createSelector<usersReducer, UserList, MakeGetUserListFilters>(getUsers, ({ params }) => params);

export const makeGetUsersNextLink = () => createSelector<usersReducer, UserList, MakeGetUserListNextLink>(getUsers, ({ data }) => data?.nextBatchLink);

export const makeGetUserListStatus = () =>
  createSelector<usersReducer, UserList, ReturnType<typeof getDataStatus>>(getUsers, ({ data, error, isLoading }) =>
    getDataStatus({ isLoading, error, data: data?.users })
  );

export const makeGetUserListError = () => createSelector<usersReducer, UserList, MakeGetUserListError>(getUsers, ({ error }) => error);

type SearchByOptions = usersReducer['users']['searchByOptions'];
type MakeGetSearchByOptionsData = UserListSearchByOptionListVM['options'];

export const makeGetSearchOptionList = () =>
  createSelector<usersReducer, SearchByOptions, MakeGetSearchByOptionsData>(getSearchByOptions, ({ data }) => (data === undefined ? [] : data.options));

export const makeGetSearchOptionStatus = () =>
  createSelector<usersReducer, SearchByOptions, ReturnType<typeof getDataStatus>>(getSearchByOptions, getDataStatus);

type UsersApps = usersReducer['users']['usersApps'];
type MakeGetUsersAppsData = UsersAppsListVM['list'];

export const makeGetUsersAppsList = () =>
  createSelector<usersReducer, UsersApps, MakeGetUsersAppsData>(getUsersApps, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetUsersAppsListStatus = () => createSelector<usersReducer, UsersApps, ReturnType<typeof getDataStatus>>(getUsersApps, getDataStatus);

type AppDictionary = usersReducer['users']['appDictionary'];
type MakeGetAppDictionary = AppDictionaryVM['list'];

export const makeGetAppDictionary = () =>
  createSelector<usersReducer, AppDictionary, MakeGetAppDictionary>(getAppDictionary, ({ data }) => (data === undefined ? [] : data.list));

type PlatformDictionary = usersReducer['users']['platformDictionary'];
type MakeGetPlatformDictionary = PlatformDictionaryVM['list'];

export const makeGetPlatformDictionary = () =>
  createSelector<usersReducer, PlatformDictionary, MakeGetPlatformDictionary>(getPlatformDictionary, ({ data }) => (data === undefined ? [] : data.list));

type MakeGetHistoryChangesData = HistoryChangesListVM['list'];
type MakeGetHistoryChangesParams = HistoryChanges['params'];
type MakeGetHistoryChangesNextPage = HistoryChangesListVM['pagination'];

export const makeGetHistoryChanges = () =>
  createSelector<usersReducer, HistoryChanges, MakeGetHistoryChangesData>(getHistoryChanges, ({ data }) => (data === undefined ? [] : data.list));
export const makeGetHistoryChangesNextPage = () =>
  createSelector<usersReducer, HistoryChanges, MakeGetHistoryChangesNextPage>(getHistoryChanges, ({ data }) => data?.pagination);
export const makeGetHistoryChangesStatus = () =>
  createSelector<usersReducer, HistoryChanges, ReturnType<typeof getDataStatus>>(getHistoryChanges, getDataStatus);
export const makeGetHistoryChangesPagination = () =>
  createSelector<usersReducer, HistoryChanges, MakeGetHistoryChangesParams>(getHistoryChanges, ({ params }) => params);

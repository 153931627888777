import * as Yup from 'yup';

import { CommonValidationError } from './models';

export const NUMBER_LOCALE: Yup.LocaleObject['number'] = {
  min: ({ min }) => ({ key: 'validation.number.min', values: { min } } as CommonValidationError),
  max: ({ max }) => ({ key: 'validation.number.max', values: { max } } as CommonValidationError),
};

export const numberValidator = Yup.number().typeError('validation.number.type');

import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { type ProviderClassificationsDictionaryVM } from '@/models/provider-classifications';

export const providerClassificationsActions = createDictionaryActions(
  '[PROVIDER CLASSIFICATIONS DICTIONARY] FETCH REQUEST',
  '[PROVIDER CLASSIFICATIONS DICTIONARY] FETCH SUCCESS',
  '[PROVIDER CLASSIFICATIONS DICTIONARY] FETCH FAILURE',
  '[PROVIDER CLASSIFICATIONS DICTIONARY] FETCH CANCEL',
  '[PROVIDER CLASSIFICATIONS DICTIONARY] FETCH SILENT_REQUEST'
)<undefined, ProviderClassificationsDictionaryVM>();

export type providerClassificationsActions = Action | ActionType<typeof providerClassificationsActions>;

import { type SelectOption } from '@/common/components/form-controls-deprecated';

type ProviderClassificationsResponse = {
  id: string;
  displayName: string;
  translationId: string;
};

export type ProviderClassificationsListResponse = {
  entries: ProviderClassificationsResponse[];
};

export interface ProviderClassificationsDictionaryElementVM extends SelectOption {}
export class ProviderClassificationsDictionaryElementVM {
  constructor({ id, displayName }: ProviderClassificationsResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface ProviderClassificationsDictionaryVM {
  list: ProviderClassificationsDictionaryElementVM[];
}

export class ProviderClassificationsDictionaryVM {
  constructor(list: ProviderClassificationsListResponse) {
    this.list = (list.entries || []).map(element => new ProviderClassificationsDictionaryElementVM(element));
  }
}

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';

import { getEnv, CommonError, AllowNull } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import {
  UserListResponse,
  UserListVM,
  UserDetailsResponse,
  UserDetailsVM,
  UserListParams,
  UpdateUserDetailsParams,
  UsersAutocompleteListVM,
  UsersAppsListResponse,
  UsersAppsListVM,
} from '../models/users';

export const EMAIL_ALREADY_EXIST_ERROR_CODE = 'ADM_PNL-009';
export const DEACTIVATE_LAST_ORG_ADMIN_ERROR_CODE = 'CP-008';
export const DEACTIVATE_NOT_AUTHORIZED_ERROR_CODE = 'UA-CU-103';

const config = getEnv();

const USER_DATA_PATH = '/admin-panel-api/v1/users/search';
const USER_DATA_ENDPOINT = `${config.REACT_APP_API_URL}/oauth/v1/admin/users`;
const USER_DETAILS_ENDPOINT = `${config.REACT_APP_API_URL}/admin-panel-api/v1/users`;
const UPDATE_USER_DETAILS_DATA_ENDPOINT = `${config.REACT_APP_API_URL}/admin-panel-api/v1/users/update`;
const DEACTIVATE_USER_ENDPOINT = `${config.REACT_APP_API_URL}/oauth/v1/admin/users`;
const REMOVE_USER_ENDPOINT = `${config.REACT_APP_API_URL}/oauth/v1/admin/users`;
const REACTIVATE_USER_ENDPOINT = `${config.REACT_APP_API_URL}/oauth/v1/admin/users`;
const USERS_APPS_ENDPOINT = `${config.REACT_APP_API_URL}/user-account-api/v1/admin/users`;

const getUserStateValue = (stateFilterValue: string[] | undefined) => {
  if (stateFilterValue === undefined || stateFilterValue.length === 0 || stateFilterValue.length === 2) {
    return 'ANY';
  }

  if (stateFilterValue.includes('INACTIVE')) {
    return 'DISABLED';
  }

  if (stateFilterValue.includes('ACTIVE')) {
    return 'ACTIVE';
  }
};

export const getUserData = (data: UserListParams) => {
  const body = {
    ...omit(data, ['name', 'userState']),
    searchPhrase: data.name,
    userState: getUserStateValue(data.userState),
    organisation: data.organisation?.value,
  };
  if (data.currentRegionUrl === undefined) {
    return of(new CommonError());
  }

  return httpClient()
    .authorized.post<UserListResponse>(`${data.currentRegionUrl}${USER_DATA_PATH}`, body)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new UserListVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getUsersAutocompleteOptionList = (userName: string) =>
  httpClient()
    .authorized.get<UserListResponse>(`${USER_DATA_ENDPOINT}?basicModel=true&searchValue=${userName}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new UsersAutocompleteListVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getUserDetailsData = (id: string) =>
  httpClient()
    .authorized.get<UserDetailsResponse>(`${USER_DETAILS_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new UserDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const updateUserDetails = (userId: string, data: AllowNull<UpdateUserDetailsParams>) =>
  httpClient()
    .authorized.put<undefined>(`${UPDATE_USER_DETAILS_DATA_ENDPOINT}/${userId}`, data)
    .pipe(
      map(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        const data = e?.response?.data || e?.data;
        if (data.code === EMAIL_ALREADY_EXIST_ERROR_CODE) {
          return of(new CommonError({ code: '500', message: EMAIL_ALREADY_EXIST_ERROR_CODE }));
        }

        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const removeUsersAccess = (id: string) =>
  httpClient()
    .authorized.put(`${REMOVE_USER_ENDPOINT}/${id}/detachOrgAndRole`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.data })))
    );

export const deactivateUser = (id: string) =>
  httpClient()
    .authorized.delete(`${DEACTIVATE_USER_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.data })))
    );

export const reactivateUser = (id: string) =>
  httpClient()
    .authorized.put(`${REACTIVATE_USER_ENDPOINT}/${id}/restore`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => {
        return of(new CommonError({ code: '500', message: e }));
      })
    );

export const getUsersAppsList = (id: string) =>
  httpClient()
    .authorized.get<UsersAppsListResponse>(`${USERS_APPS_ENDPOINT}/${id}/apps-usage`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new UsersAppsListVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

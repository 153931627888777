import dayjs, { Dayjs } from 'dayjs';
import upperFirst from 'lodash/upperFirst';

import { DEFAULT_DATE_FORMAT, Enum } from '@/common/utils';
import { DropdownOption } from '@/common/components/search-box';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { SelectOption } from '@/common/components/form-controls-deprecated';
import { AutocompleteOption } from '@/common/components/autocomplete';

export const ReviewReported = Enum('TRUE', 'FALSE');
export type ReviewReported = Enum<typeof ReviewReported>;

export type ReviewListEntityResponse = {
  id: string;
  ratingDate?: string;
  author?: Author;
  validationStatus?: string;
  targetType?: string;
  application?: string;
  rating?: number;
  activeModerationRequestIds?: string[];
  reported?: boolean;
};

export type ReviewListRequest = ApiPagination<{
  text?: string;
  authorId?: AutocompleteOption;
  statuses?: string[];
  targetTypes?: string[];
  targetType?: string;
  applications?: string[];
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  rating?: number;
  reported?: string[];
  currentRegionUrl?: string | undefined;
  ratingContentTypes?: string[];
}>;

export type ReviewListResponse = PageableListResponse<ReviewListEntityResponse>;

export interface ReviewListElementVM extends ReviewListEntityResponse {}
export class ReviewListElementVM {
  constructor({ ratingDate, targetType, reported, ...props }: ReviewListEntityResponse) {
    Object.assign(this, {
      ...props,
      ratingDate: ratingDate ? dayjs(ratingDate).format(DEFAULT_DATE_FORMAT) : '',
      targetType: targetType ? upperFirst(targetType.toLowerCase()) : '',
      reported: reported ? ReviewReported.TRUE : false,
    });
  }
}

export class ReviewListVM extends PageableListVM<ReviewListElementVM, ReviewListResponse> {}

type Author = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roleId: string;
  organisationName: string;
};

type Reports = {
  id: string;
  contentType: string;
  contentId: string;
};

type File = {
  url: string;
  size: number;
};

type Files = {
  small: File;
  medium: File;
  large: File;
};

export type ReviewMedia = {
  id: string;
  origin: {
    creatorUserId: string;
  };
  files: Files;
  type: string;
  validationStatus: string;
  reports: Reports[];
  reported: boolean;
};

export type ReviewDetailsResponse = {
  id: number;
  creationDate: string;
  context: string;
  contextId: string;
  status: string;
  author: Author;
  rating: number;
  review: string;
  media: ReviewMedia[];
  reports: Reports[];
  reported: boolean;
};

export interface ReviewDetailsVM extends ReviewDetailsResponse {}
export class ReviewDetailsVM {
  constructor({ creationDate, ...props }: ReviewDetailsResponse) {
    Object.assign(this, {
      ...props,
      creationDate: creationDate ? dayjs(creationDate).format(DEFAULT_DATE_FORMAT) : '',
    });
  }
}

export type ReviewDetailsRequest = {
  id: string;
  regionCode?: string;
  idAsLong?: number;
  idAsString?: string;
};

type ReviewStatusResponse = {
  displayName: string;
  id: string;
};

export type ReviewStatusListResponse = ReviewStatusResponse[];

export interface ReviewStatusDictionaryElementVM extends SelectOption {}

export class ReviewStatusDictionaryElementVM {
  constructor({ displayName, id, ...props }: ReviewStatusResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface ReviewStatusDictionaryVM {
  list: ReviewStatusDictionaryElementVM[];
}

export class ReviewStatusDictionaryVM {
  constructor(list: ReviewStatusListResponse) {
    this.list = (list || []).map(element => new ReviewStatusDictionaryElementVM(element));
  }
}

type RatingContentTypeResponse = {
  displayName: string;
  id: string;
};

export type RatingContentTypeListResponse = RatingContentTypeResponse[];

export interface RatingContentTypeDictionaryElementVM extends SelectOption {}

export class RatingContentTypeDictionaryElementVM {
  constructor({ displayName, id, ...props }: RatingContentTypeResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface RatingContentTypeDictionaryVM {
  list: RatingContentTypeDictionaryElementVM[];
}

export class RatingContentTypeDictionaryVM {
  constructor(list: RatingContentTypeListResponse) {
    this.list = (list || []).map(element => new RatingContentTypeDictionaryElementVM(element));
  }
}

type ReviewTargetTypesResponse = {
  displayName: string;
  id: string;
};

export type ReviewTargetTypesListResponse = ReviewTargetTypesResponse[];

export interface ReviewTargetTypesDictionaryElementVM extends SelectOption {}

export class ReviewTargetTypesDictionaryElementVM {
  constructor({ displayName, id, ...props }: ReviewTargetTypesResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface ReviewTargetTypesDictionaryVM {
  list: ReviewTargetTypesDictionaryElementVM[];
}

export class ReviewTargetTypesDictionaryVM {
  constructor(list: ReviewTargetTypesListResponse) {
    this.list = (list || []).map(element => new ReviewTargetTypesDictionaryElementVM(element));
  }
}

type ReviewApplicationsResponse = {
  displayName: string;
  id: string;
};

export type ReviewApplicationsListResponse = ReviewApplicationsResponse[];

export interface ReviewApplicationsDictionaryElementVM extends SelectOption {}

export class ReviewApplicationsDictionaryElementVM {
  constructor({ displayName, id, ...props }: ReviewApplicationsResponse) {
    Object.assign(this, {
      ...props,
      label: displayName,
      value: id,
    });
  }
}

export interface ReviewApplicationsDictionaryVM {
  list: ReviewApplicationsDictionaryElementVM[];
}

export class ReviewApplicationsDictionaryVM {
  constructor(list: ReviewApplicationsListResponse) {
    this.list = (list || []).map(element => new ReviewApplicationsDictionaryElementVM(element));
  }
}

export type ReviewListSearchByOptionResponse = {
  displayName: string;
  id: string;
};

export type ReviewListSearchByOptionListResponse = ReviewListSearchByOptionResponse[];

export interface ReviewListSearchByOptionVM extends DropdownOption {}

export class ReviewListSearchByOptionVM {
  constructor(option: ReviewListSearchByOptionResponse) {
    this.value = option.id;
    this.label = option.displayName;
  }
}

export interface ReviewListSearchByOptionListVM {
  options: ReviewListSearchByOptionVM[];
}

export class ReviewListSearchByOptionListVM {
  constructor(options: ReviewListSearchByOptionListResponse) {
    this.options = (options || []).map(element => new ReviewListSearchByOptionVM(element));
  }
}

export type BulkDeleteReviewElement = {
  id: string;
};

export type BulkDeleteReviewList = BulkDeleteReviewElement[];

import React, { FC, HTMLAttributes } from 'react';
import MUITooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material';

import { Icon } from '../icon';
import { tooltipContentStyles, tooltipStyle, childWrapperStyle } from './styles';

export type placementTypes =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export type TooltipProps = HTMLAttributes<HTMLElement> & {
  message?: string;
  messageAsJsx?: JSX.Element | string;
  placement?: placementTypes;
  withIcon?: boolean;
  iconName?: string;
};

// TODO: https://github.com/mui-org/material-ui/issues/13394

export const Tooltip: FC<TooltipProps> = ({ children, message, messageAsJsx, placement = 'top', withIcon = true, iconName = 'mdi-information' }) => {
  return (
    <ThemeProvider theme={tooltipStyle}>
      <MUITooltip
        title={
          <div css={tooltipContentStyles}>
            {withIcon && <Icon name={iconName} className='tooltip-icon' />}
            {message && <div className='tooltip-message' dangerouslySetInnerHTML={{ __html: message }} />}
            {messageAsJsx && <div className='tooltip-message'>{messageAsJsx}</div>}
          </div>
        }
        placement={placement}
      >
        <div css={childWrapperStyle}>{children}</div>
      </MUITooltip>
    </ThemeProvider>
  );
};

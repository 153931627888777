import React, { FC, useMemo, useCallback, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Autocomplete, AutocompleteOnChangeFunc } from '@/common/components/autocomplete';
import { ProtectedContent } from '@/common/components/protected-content';
import { CheckboxGroup, CheckboxGroupChangeFunc } from '@/common/components/form-controls-deprecated/checkbox-group';
import { Filter, FilterWrapper, OnSliderFilterChangeFunc, RegionFilter, useHandleClearCurrentRegion, SliderFilter, Range } from '@/common/components/filter';
import { CollapsibleFilter } from '@/common/components/collapsible-filter';
import { DistanceFilter, DistanceFilterChangeFunc } from '@/common/components/filter/components/distance-filter';
import { useTourStepRef } from '@/common/components/onboarding';
import { makeGetVisibilityData, makeGetVisibilityStatus } from '@/store/visibility';
import { routesActions, makeGetRouteListStatus, makeGetRouteListFilters } from '@/store/routes';
import { makeGetDifficultiesData, makeGetDifficultiesStatus } from '@/store/difficulties';
import { makeGetActivitiesData, makeGetActivitiesStatus } from '@/store/activities';
import { RadioGroup, Option, RadioOption, CommonRadioOnChange, Select, SelectOption, Checkbox } from '@/common/components/form-controls-deprecated';
import { makeGetRouteProviderClassificationsData, makeGetRouteProviderClassificationsStatus } from '@/store/route-provider-classification';
import { getOrganisationsAutocompleteOptionList } from '@/features/accounts/services/organisations';
import { makeGetUserStatus } from '@/store/authentication';
import { useUsersAutocomplete } from '@/common/hooks';
import { RoutesRecommended } from '@/features/content/models/routes';
import { Enum } from '@/common/utils';
import { getOnboardingSteps } from '../../hooks/routes-list-onboarding';
import { style } from './styles';

export const RouteTypeValues = Enum('CIRCULAR', 'POINTTOPOINT');
export type RouteTypeValues = Enum<typeof RouteTypeValues>;

export const RouteRecommendedValues = Enum('YES', 'NO');
export type RouteRecommendedValues = Enum<typeof RouteRecommendedValues>;

export const routeStateOptions: Option[] = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Deleted', value: 'DELETED' },
];

const AVERAGE_RATING_RANGE: Range<number> = {
  start: 1,
  end: 5,
};

const getRouteListFilters = makeGetRouteListFilters();
const getRoutesListStatus = makeGetRouteListStatus();
const getDifficultiesData = makeGetDifficultiesData();
const getDifficultiesStatus = makeGetDifficultiesStatus();
const getVisibilityData = makeGetVisibilityData();
const getVisibilityStatus = makeGetVisibilityStatus();
const getActivitiesData = makeGetActivitiesData();
const getActivitiesStatus = makeGetActivitiesStatus();
const getUserStatus = makeGetUserStatus();
const getRouteProviderClassificationsDictionary = makeGetRouteProviderClassificationsData();
const getRouteProviderClassificationsStatus = makeGetRouteProviderClassificationsStatus();

type RoutesListFiltersProps = {
  isBulkChangeEnabled: boolean;
};

export const RoutesListFilters: FC<RoutesListFiltersProps> = ({ isBulkChangeEnabled }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const handleClearCurrentRegion = useHandleClearCurrentRegion();
  const filtersTourStep = useMemo(() => getOnboardingSteps('filters'), []);
  const [filtersRef] = useTourStepRef<HTMLDivElement>(filtersTourStep);

  const routeListFilters = useSelector(getRouteListFilters);
  const routeListStatus = useSelector(getRoutesListStatus);
  const difficultiesData = useSelector(getDifficultiesData);
  const difficultiesStatus = useSelector(getDifficultiesStatus);
  const visibilityData = useSelector(getVisibilityData);
  const visibilityStatus = useSelector(getVisibilityStatus);
  const activitiesData = useSelector(getActivitiesData);
  const activitiesStatus = useSelector(getActivitiesStatus);
  const { currentRegion } = useSelector(getUserStatus);
  const routeProviderClassificationsDictionary = useSelector(getRouteProviderClassificationsDictionary);
  const routeProviderClassificationsStatus = useSelector(getRouteProviderClassificationsStatus);

  const UsersAutocomplete = useUsersAutocomplete({
    initialOptions: routeListFilters.createdBy ? [routeListFilters.createdBy] : [],
  });

  const routeRecommendedOptions: Option[] = useMemo(
    () => [
      { label: t('label.yes'), value: RouteRecommendedValues.YES },
      { label: t('label.no'), value: RouteRecommendedValues.NO },
    ],
    [t]
  );

  const routeTypeOptions: Option[] = useMemo(
    () => [
      { label: t('label.circular'), value: RouteTypeValues.CIRCULAR },
      { label: t('label.pointToPoint'), value: RouteTypeValues.POINTTOPOINT },
    ],
    [t]
  );

  const handleVisibilityFilterChange = useCallback<CheckboxGroupChangeFunc>(
    visibilities => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, visibilities: visibilities.map(value => String(value)) },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleActivitiesFilterChange = useCallback<CheckboxGroupChangeFunc>(
    types => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, types: types.map(value => String(value)) },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleDifficultiesFilterChange = useCallback<CheckboxGroupChangeFunc>(
    difficulties => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, difficulties: difficulties.map(value => String(value)) },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleRouteTypeFilterChange = useCallback<CheckboxGroupChangeFunc>(
    routeTypes => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, routeType: routeTypes.map(value => String(value)) as RouteTypeValues[] },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleRecommendedFilterChange = useCallback<CheckboxGroupChangeFunc>(
    values => {
      const elements = new Set<string>();
      values.forEach(element => {
        if (element === RouteRecommendedValues.YES) {
          elements.add(RoutesRecommended.APPROVED);
        } else {
          elements.add(RoutesRecommended.APPROVAL_REQUIRED);
          elements.add(RoutesRecommended.NOT_APPROVED);
        }
      });

      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, recommended: Array.from(elements) },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleDistanceFilterChange = useCallback<DistanceFilterChangeFunc>(
    ({ start, end }) => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, minDistance: start, maxDistance: end },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  type SelectOnChangeFunc = (value: SelectOption) => void;

  const handleOrganisationFilterChange = useCallback<SelectOnChangeFunc>(
    option => {
      dispatch(
        routesActions.list.request({
          params: {
            currentRegionUrl: currentRegion?.url,
            page: 0,
            organisationId: option || undefined,
          },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleIsOrganisationalFilterChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      dispatch(
        routesActions.list.request({
          params: {
            currentRegionUrl: currentRegion?.url,
            page: 0,
            isOrganisational: event.target.checked,
          },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleStateFilterChange = useCallback<CommonRadioOnChange>(
    value => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, state: String(value) },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleAuthorFilterChange = useCallback<AutocompleteOnChangeFunc>(
    option => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, createdBy: option || undefined },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleRatingFilterChange = useCallback<OnSliderFilterChangeFunc>(
    ({ rangeValue: { start, end }, checkboxValue }) => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, minRating: start, maxRating: end, includeWithoutRating: checkboxValue },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url]
  );

  const handleRouteProviderClassificationFilterChange = useCallback<CheckboxGroupChangeFunc>(
    filterValue => {
      dispatch(
        routesActions.list.request({
          params: { currentRegionUrl: currentRegion?.url, page: 0, providerClassifications: filterValue as string[] },
          options: { mergeWithPrevProps: true },
        })
      );
    },
    [currentRegion?.url, dispatch]
  );
  const handleClearAll = useCallback<() => void>(() => {
    const defaultRegion = handleClearCurrentRegion();
    UsersAutocomplete.clearOptions();

    dispatch(
      routesActions.list.request({
        params: {
          currentRegionUrl: defaultRegion?.url,
          page: 0,
          maxDistance: undefined,
          minDistance: undefined,
          organisationId: undefined,
          types: undefined,
          difficulties: undefined,
          state: 'ACTIVE',
          createdBy: undefined,
          visibilities: undefined,
          minRating: undefined,
          maxRating: undefined,
          recommended: [],
          routeType: [],
          includeWithoutRating: true,
          isOrganisational: false,
        },
        options: { mergeWithPrevProps: true },
      })
    );
  }, [currentRegion?.url]);

  return (
    <div css={style}>
      <Filter onClear={handleClearAll} ref={filtersRef} disabled={isBulkChangeEnabled}>
        <RegionFilter disabled={routeListStatus === 'loading' || isBulkChangeEnabled} defaultOpen />

        <ProtectedContent subject='ROUTES' scope='RESTORE'>
          <CollapsibleFilter label={t('label.recordStatus')} activeFiltersCount={1}>
            <RadioGroup
              data={routeStateOptions as RadioOption[]}
              value={routeListFilters.state || routeStateOptions[0].value}
              onChange={handleStateFilterChange}
              disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            />
          </CollapsibleFilter>
        </ProtectedContent>

        <CollapsibleFilter label={t('label.visibility')} activeFiltersCount={routeListFilters.visibilities?.length || 0}>
          <CheckboxGroup
            status={visibilityStatus}
            options={visibilityData?.list || []}
            value={routeListFilters.visibilities || []}
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            onChange={handleVisibilityFilterChange}
            minHeight={58}
          />
        </CollapsibleFilter>

        <CollapsibleFilter label={t('label.activityType')} activeFiltersCount={routeListFilters.types?.length || 0}>
          <CheckboxGroup
            status={activitiesStatus}
            options={activitiesData?.list || []}
            value={routeListFilters.types || []}
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            onChange={handleActivitiesFilterChange}
          />
        </CollapsibleFilter>

        <CollapsibleFilter label={t('label.difficulty')} activeFiltersCount={routeListFilters.difficulties?.length || 0}>
          <CheckboxGroup
            status={difficultiesStatus}
            options={difficultiesData?.list || []}
            value={routeListFilters.difficulties || []}
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            onChange={handleDifficultiesFilterChange}
          />
        </CollapsibleFilter>

        <CollapsibleFilter label={t('label.routeType')} activeFiltersCount={routeListFilters.routeType?.length || 0}>
          <CheckboxGroup
            status={activitiesStatus}
            options={routeTypeOptions || []}
            value={routeListFilters.routeType || []}
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            onChange={handleRouteTypeFilterChange}
            minHeight={58}
          />
        </CollapsibleFilter>

        <FilterWrapper label={t('label.distanceInKm')}>
          <DistanceFilter
            value={{ start: routeListFilters.minDistance, end: routeListFilters.maxDistance }}
            onChange={handleDistanceFilterChange}
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
          />
        </FilterWrapper>

        <FilterWrapper label={t('label.recommended')}>
          <CheckboxGroup
            options={routeRecommendedOptions}
            value={
              (routeListFilters.recommended &&
                routeListFilters.recommended.map(element =>
                  element === RoutesRecommended.APPROVED ? RouteRecommendedValues.YES : RouteRecommendedValues.NO
                )) ||
              []
            }
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            onChange={handleRecommendedFilterChange}
            minHeight={58}
          />
        </FilterWrapper>

        <ProtectedContent subject='ORGANISATIONS' scope='SEARCH' realm='ANY'>
          <FilterWrapper label={t('label.organisation')} className='organiastion-filter'>
            <Checkbox
              label={t('label.showNoOrgRoutes')}
              onChange={handleIsOrganisationalFilterChange}
              checked={routeListFilters.isOrganisational}
              disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
              className='organisation-filter-checkbox'
            />
            <Select
              placeholder={t('placeholder.enterOrganisation')}
              onChange={handleOrganisationFilterChange}
              className='select'
              disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
              value={routeListFilters.organisationId}
              loadOptionService={getOrganisationsAutocompleteOptionList}
              clearable
            />
          </FilterWrapper>
        </ProtectedContent>

        <ProtectedContent subject='USERS' scope='FILTER_AUTHORS'>
          <FilterWrapper label={t('label.author')}>
            <Autocomplete
              options={UsersAutocomplete.options || []}
              placeholder={t('placeholder.enterAuthor')}
              onInputChange={UsersAutocomplete.handleOnInputChange}
              onChange={handleAuthorFilterChange}
              className='select'
              disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
              value={routeListFilters.createdBy}
              loading={UsersAutocomplete.isOptionListLoading}
            />
          </FilterWrapper>
        </ProtectedContent>

        <CollapsibleFilter label={t('label.averageRating')} activeFiltersCount={1}>
          <SliderFilter
            disabled={routeListStatus === 'loading' || isBulkChangeEnabled}
            value={{ start: routeListFilters.minRating, end: routeListFilters.maxRating }}
            defaultValue={AVERAGE_RATING_RANGE}
            min={AVERAGE_RATING_RANGE.start}
            max={AVERAGE_RATING_RANGE.end}
            onChange={handleRatingFilterChange}
            checkboxProps={{
              checked: routeListFilters.includeWithoutRating,
              label: t('label.includeNotRated'),
            }}
          />
        </CollapsibleFilter>

        <ProtectedContent subject='ORGANISATIONS' scope='SEARCH' realm='ANY'>
          <CollapsibleFilter label={t('label.trustedLevel')} activeFiltersCount={routeListFilters.providerClassifications?.length || 0}>
            <CheckboxGroup
              options={routeProviderClassificationsDictionary?.list || []}
              value={routeListFilters.providerClassifications || []}
              disabled={routeProviderClassificationsStatus === 'loading' || routeListStatus === 'loading'}
              onChange={handleRouteProviderClassificationFilterChange}
              minHeight={50}
            />
          </CollapsibleFilter>
        </ProtectedContent>
      </Filter>
    </div>
  );
};

import { createReducer } from 'typesafe-actions';

import { type HistoryChangesListVM } from '@/models/history-changes';
import { type FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { type FeatureStateType } from '../../helpers';
import { organisationsActions } from '../actions';

export type historyChangesState = FetchState<HistoryChangesListVM>;

const INITIAL_STATE: historyChangesState = getFetchInitialState<HistoryChangesListVM>({ size: 5 });

export const historyChangesSuccessReducer = <State extends FetchState, Payload extends HistoryChangesListVM>(
  initialState: State,
  state: State,
  payload: Payload
) => {
  const newState = fetchReducerHelpers.success(initialState, state, payload);
  const { list } = payload;

  const newList = [...(state.data?.list ? state.data?.list : []), ...list];

  return {
    ...newState,
    data: newState.data === undefined ? undefined : { ...newState.data, list: newList },
  };
};

const reducer = createReducer<historyChangesState, organisationsActions>(INITIAL_STATE)
  .handleAction(organisationsActions.historyChanges.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(organisationsActions.historyChanges.success, (state, { payload }) => historyChangesSuccessReducer(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.historyChanges.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(organisationsActions.clearHistoryChanges, () => ({ ...INITIAL_STATE }));

export const historyChangesReducer = { historyChanges: reducer };
export type historyChangesReducer = FeatureStateType<typeof historyChangesReducer>;

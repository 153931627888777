import React, { FC, ButtonHTMLAttributes, useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from '@mui/material';

import { makeGetUserData } from '@/store/authentication';
import { Icon } from '@/common/components/icon';
import { Avatar } from '@/common/components/avatar';
import { useMuiStyles, useStyles } from '@/styles/hooks';
import { dropdownTheme } from '@/styles/dropdown-style';
import { UserInfo } from './components/user-info';
import { buttonStyle, menuStyle } from './styles';

const getUserData = makeGetUserData();

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

export const ProfileDropdown: FC<Props> = ({ children, className, ...props }) => {
  const userData = useSelector(getUserData);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false);
  const DropdownThemeStyles = useMuiStyles(dropdownTheme);
  const ButtonThemeStyles = useStyles(buttonStyle);
  const MenuThemeStyles = useStyles(menuStyle);

  const handleOpenProfileDropdown = useCallback(() => setProfileDropdownOpen(prev => !prev), []);
  const handleCloseProfileDropdown = useCallback(() => setProfileDropdownOpen(false), []);

  const handleOnMousedown = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => event.preventDefault();
  const handleOnFocus = () => document.getElementById('LOGOUT_BUTTON')?.focus();

  return (
    <>
      <button {...props} css={ButtonThemeStyles.styles} onClick={handleOpenProfileDropdown} ref={anchorRef}>
        <Avatar firstName={userData?.firstName || ''} lastName={userData?.lastName || ''} className='avatar' size='small' theme='secondary' />
        {userData?.firstName} {userData?.lastName}
        <Icon name='mdi-chevron-down' className='dropdown-icon' />
      </button>

      {anchorRef.current !== null && (
        <ThemeProvider theme={DropdownThemeStyles.styles}>
          <Menu
            id='dropdown-profile'
            anchorEl={anchorRef.current}
            keepMounted
            open={isProfileDropdownOpen}
            onClose={handleCloseProfileDropdown}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 0,
            }}
            autoFocus={false}
            css={MenuThemeStyles.styles}
          >
            <MenuItem disableRipple onFocus={handleOnFocus} onMouseDown={handleOnMousedown}>
              <UserInfo />
            </MenuItem>
          </Menu>
        </ThemeProvider>
      )}
    </>
  );
};

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Styles as RJStyles } from 'react-joyride';
import { GroupBase, StylesConfig } from 'react-select';
import { Theme } from '@mui/material';
import { css, Interpolation, SerializedStyles } from '@emotion/core';

import { makeGetCurrentTheme } from '@/store/interface';
import { SelectValue } from '@/common/components/form-controls-deprecated';
import { StyleFunction } from './models';
import { themeLight } from './theme';

const getCurrentTheme = makeGetCurrentTheme();

type UseStylesData = {
  styles: SerializedStyles[] | any;
  themeName: string;
};
export const useStyles = <R extends Interpolation = Interpolation>(styleFunctions: StyleFunction<R> | StyleFunction<R>[]): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);
  const isDebugMode = localStorage.getItem('currentTheme') !== null; //to be removed, after darkmode finished

  return useMemo(() => {
    if (isDebugMode) {
      if (!Array.isArray(styleFunctions)) {
        return { styles: [css(styleFunctions({ theme, themeName }))] as const, themeName };
      }

      return { styles: styleFunctions.reduce((arr, func) => [...arr, css(func({ theme, themeName }))], [] as SerializedStyles[]), themeName };
    }

    if (!Array.isArray(styleFunctions)) {
      return { styles: [css(styleFunctions({ theme: themeLight, themeName: 'LIGHT' }))] as const, themeName };
    }

    return {
      styles: styleFunctions.reduce((arr, func) => [...arr, css(func({ theme: themeLight, themeName: 'LIGHT' }))], [] as SerializedStyles[]),
      themeName,
    };
  }, [styleFunctions, theme, themeName]);
};

export const useMuiStyles = <R extends Theme = Theme>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);
  const isDebugMode = localStorage.getItem('currentTheme') !== null; //to be removed, after darkmode finished

  return useMemo(() => {
    if (isDebugMode) {
      return { styles: styleFunctions({ theme, themeName }), themeName };
    }

    return { styles: styleFunctions({ theme: themeLight, themeName: 'LIGHT' }), themeName };
  }, [styleFunctions, theme, themeName]);
};

export const useJoyrideStyles = <R extends RJStyles = RJStyles>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);
  const isDebugMode = localStorage.getItem('currentTheme') !== null; //to be removed, after darkmode finished

  return useMemo(() => {
    if (isDebugMode) {
      return { styles: styleFunctions({ theme, themeName }), themeName };
    }

    return { styles: styleFunctions({ theme: themeLight, themeName: 'LIGHT' }), themeName };
  }, [styleFunctions, theme, themeName]);
};

export const useReactSelectStyles = <R extends StylesConfig<SelectValue, false, GroupBase<SelectValue>>>(styleFunctions: StyleFunction<R>): UseStylesData => {
  const { theme, themeName } = useSelector(getCurrentTheme);
  const isDebugMode = localStorage.getItem('currentTheme') !== null; //to be removed, after darkmode finished

  return useMemo(() => {
    if (isDebugMode) {
      return { styles: styleFunctions({ theme, themeName }), themeName };
    }

    return { styles: styleFunctions({ theme: themeLight, themeName: 'LIGHT' }), themeName };
  }, [styleFunctions, theme, themeName]);
};

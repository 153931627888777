import { ActionType, Action, createAction } from 'typesafe-actions';

import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import { MediaStatus } from '@/common/components/photo-details-modal/components/top-bar';
import { GalleryApplicationsDictionaryVM, GalleryListVM, GalleryParams, GalleryStatusDictionaryVM, ModalImagesList } from '@/models/gallery';

export const galleryActions = {
  list: createFetchActions('FETCH_GALLERY_REQUEST', 'FETCH_GALLERY_SUCCESS', 'FETCH_GALLERY_FAILURE')<GalleryParams, GalleryListVM>(),
  clearGalleryList: createAction('CLEAR_GALLERY_LIST')(),
  status: createDictionaryActions(
    'FETCH_GALLERY_STATUS_DICTIONARY_REQUEST',
    'FETCH_GALLERY_STATUS_DICTIONARY_SUCCESS',
    'FETCH_GALLERY_STATUS_DICTIONARY_FAILURE',
    'FETCH_GALLERY_STATUS_DICTIONARY_CANCEL',
    'FETCH_GALLERY_STATUS_DICTIONARY_SILENT_REQUEST'
  )<undefined, GalleryStatusDictionaryVM>(),
  application: createDictionaryActions(
    'FETCH_GALLERY_APPLICATION_DICTIONARY_REQUEST',
    'FETCH_GALLERY_APPLICATION_DICTIONARY_SUCCESS',
    'FETCH_GALLERY_APPLICATION_DICTIONARY_FAILURE',
    'FETCH_GALLERY_APPLICATION_DICTIONARY_CANCEL',
    'FETCH_GALLERY_APPLICATION_DICTIONARY_SILENT_REQUEST'
  )<undefined, GalleryApplicationsDictionaryVM>(),
  removeGalleryItem: createAction('DELETE_GALLERY_LIST')<string>(),
  changeGalleryItemReviewedStatus: createAction('SET_GALLERY_LIST_ITEM_REVIEWED_STATUS')<{ mediaId: string; isReviewed: boolean }>(),
  setModalImagesList: createAction('SET_MODAL_IMAGES_LIST')<ModalImagesList>(),
  setModalImagesListLoading: createAction('SET_MODAL_IMAGES_LIST_LOADING_STATE')<boolean>(),
  setModalImagesListHasPrev: createAction('SET_MODAL_IMAGES_LIST_HAS_PREV')<boolean>(),
  setModalImagesListHasNext: createAction('SET_MODAL_IMAGES_LIST_HAS_NEXT')<boolean>(),
  setModalImagesListReviewedStatus: createAction('SET_MODAL_IMAGES_LIST_REVIEWED_STATUS')<{ mediaId: string; status: MediaStatus; isReviewed: boolean }>(),
  setModalImagesListDeleteStatus: createAction('SET_MODAL_IMAGES_LIST_DELETE_STATUS')<{ mediaId: string; status: MediaStatus }>(),
  setModalImagesListDeleteItem: createAction('SET_MODAL_IMAGES_LIST_DELETE_ITEM')<string>(),
  setModalImagesListPageSize: createAction('SET_MODAL_IMAGES_LIST_PAGE_SIZE')<number>(),
  setModalImagesListPageIndex: createAction('SET_MODAL_IMAGES_LIST_PAGE_INDEX')<number>(),
};

export type galleryActions = Action | ActionType<typeof galleryActions>;

import { Column } from 'react-table';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { HistoryChangesVM } from '@/models/history-changes';

export type TableEntityData = Omit<HistoryChangesVM, 'author' | 'whatChanged'> & {
  author: ReactJSXElement | string;
  whatChanged: ReactJSXElement;
};

export const TABLE_COLUMN_DEFINITIONS: Column<TableEntityData>[] = [
  {
    Header: 'label.date',
    accessor: 'createdAt',
    disableSortBy: true,
  },
  {
    Header: 'label.name',
    accessor: 'author',
    disableSortBy: true,
  },
  {
    Header: 'label.action',
    accessor: 'actionTypeDisplayName',
    disableSortBy: true,
  },
  {
    Header: 'label.whatChanged',
    accessor: 'whatChanged',
    disableSortBy: true,
  },
];

export const HISTORY_CHANGES_PAGE_SIZE = 5;

import React, { FC, useEffect, useRef } from 'react';
import { useQuill } from 'react-quilljs';
import { useTranslation } from 'react-i18next';
import { QuillOptions } from 'quill';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { formats } from './constants';
import { wysiwygStyle } from './styles';
import 'quill/dist/quill.snow.css';

type Props = {
  isEditable: boolean;
  name: string;
  initValue: string;
  setValue: (html: string) => void;
};

const sanitizeUrlProtocol = (url: string): string => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }

  return url;
};

const clearFromDefaultEmptyTags = (paragraph: string): string => {
  return paragraph === '<p><br></p>' ? paragraph.replace(/<p><br><\/p>/g, '') : paragraph;
};

export const Wysiwyg: FC<Props> = ({ isEditable, initValue, setValue }) => {
  const [t] = useTranslation();
  const initialised = useRef<boolean>();
  const { styles } = useStyles(wysiwygStyle);

  const config: QuillOptions = {
    formats,
    placeholder: t('storyContentEditor.card.paragraph.placeholder'),
    modules: {
      toolbar: ['bold', 'italic', 'link'],
    },
  };

  const { quill, quillRef, Quill } = useQuill(config);

  useEffect(() => {
    if (Quill) {
      const Link = Quill.import('formats/link');
      const Italic = Quill.import('formats/italic');
      const Bold = Quill.import('formats/bold');

      Link.sanitize = sanitizeUrlProtocol;
      Italic.tagName = 'i';
      Bold.tagName = 'b';

      Quill.register(Italic, true);
      Quill.register(Bold, true);
    }
  }, [Quill]);

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initValue);
    }
  }, [quill, initValue]);

  useEffect(() => {
    if (quill) {
      quill.enable(isEditable);

      if (initialised.current !== true) {
        initialised.current = true;
      } else if (!isEditable) {
        setValue(clearFromDefaultEmptyTags(quill.root.innerHTML));
      }
    }
  }, [quill, isEditable]);

  return (
    <div css={styles} className={cn([!isEditable && 'disabled'])}>
      <div ref={quillRef} />
    </div>
  );
};

import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import {
  getRatingContentTypeData,
  getReviewApplicationsData,
  getReviewListSearchByOptionsData,
  getReviewsListData,
  getReviewStatusData,
  getReviewTargetTypesData,
} from '@/features/management/services/reviews/reviews-list';
import { CommonError, dictionaryEffectHelper, untilLocationChange } from '@/common/utils';
import { getReviewsDetailsData } from '@/features/management/services/reviews';
import { getHistoryChangesData } from '@/common/services';
import { reviewsReducer } from './reducers';
import { reviewsActions } from './actions';

const getReviewsList: Epic<reviewsActions, reviewsActions, reviewsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reviewsActions.list.request)),
    switchMap(() =>
      getReviewsListData(state$.value.reviews.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return reviewsActions.list.failure(response);
          }

          return reviewsActions.list.success(response);
        })
      )
    )
  );

const getReviewsDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(reviewsActions.details.request)),
    switchMap(action =>
      getReviewsDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return reviewsActions.details.failure(response);
            }

            return reviewsActions.details.success(response);
          })
        )
    )
  );

const getReviewStatusDictionary: Epic<reviewsActions, reviewsActions, reviewsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reviewsActions.status.request)),
    switchMap(() => {
      const state = state$.value.reviews.status;
      return dictionaryEffectHelper(() => getReviewStatusData(), state, reviewsActions.status);
    })
  ) as any;

const getRatingContentTypeDictionary: Epic<reviewsActions, reviewsActions, reviewsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reviewsActions.ratingContentType.request)),
    switchMap(() => {
      const state = state$.value.reviews.ratingContentType;
      return dictionaryEffectHelper(() => getRatingContentTypeData(), state, reviewsActions.ratingContentType);
    })
  ) as any;

const getReviewTargetTypesDictionary: Epic<reviewsActions, reviewsActions, reviewsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reviewsActions.targetType.request)),
    switchMap(() => {
      const state = state$.value.reviews.targetType;
      return dictionaryEffectHelper(() => getReviewTargetTypesData(), state, reviewsActions.targetType);
    })
  ) as any;

const getReviewApplicationsDictionary: Epic<reviewsActions, reviewsActions, reviewsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reviewsActions.application.request)),
    switchMap(() => {
      const state = state$.value.reviews.application;
      return dictionaryEffectHelper(() => getReviewApplicationsData(), state, reviewsActions.application);
    })
  ) as any;

const getSearchByOptionList: Epic<reviewsActions, reviewsActions, reviewsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reviewsActions.searchByOptions.request)),
    switchMap(() => {
      const state = state$.value.reviews.searchByOptions;
      return dictionaryEffectHelper(() => getReviewListSearchByOptionsData(), state, reviewsActions.searchByOptions);
    })
  ) as any;

export const getHistoryChangesEpic: Epic<reviewsActions, reviewsActions, reviewsReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(reviewsActions.historyChanges.request)),
    switchMap(action =>
      getHistoryChangesData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return reviewsActions.historyChanges.failure(response);
          }

          return reviewsActions.historyChanges.success(response);
        })
      )
    )
  );

export const reviewsEpics = [
  getReviewsList,
  getReviewsDetails,
  getReviewStatusDictionary,
  getReviewTargetTypesDictionary,
  getReviewApplicationsDictionary,
  getSearchByOptionList,
  getRatingContentTypeDictionary,
  getHistoryChangesEpic,
];

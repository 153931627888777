import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { providerClassificationsReducer } from './reducer';

type MakeGetProviderClassificationsData = providerClassificationsReducer['providerClassifications']['data'];

const getProviderClassifications = (state: providerClassificationsReducer) => state.providerClassifications;

export const makeGetProviderClassificationsData = () =>
  createSelector<providerClassificationsReducer, providerClassificationsReducer['providerClassifications'], MakeGetProviderClassificationsData>(
    getProviderClassifications,
    ({ data }) => data
  );

export const makeGetProviderClassificationsStatus = () =>
  createSelector<providerClassificationsReducer, providerClassificationsReducer['providerClassifications'], ReturnType<typeof getDataStatus>>(
    getProviderClassifications,
    getDataStatus
  );

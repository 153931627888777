import { SelectOption } from '@/common/components/form-controls-deprecated';

type RouteProviderClassificationsResponse = {
  id: string;
  displayName: string;
  translationId: string;
};

export type RouteProviderClassificationsListResponse = RouteProviderClassificationsResponse[];

export interface RouteProviderClassificationsDictionaryElementVM extends SelectOption {}
export class RouteProviderClassificationsDictionaryElementVM {
  constructor({ id, displayName }: RouteProviderClassificationsResponse) {
    this.value = id;
    this.label = displayName;
  }
}

export interface RouteProviderClassificationsDictionaryVM {
  list: RouteProviderClassificationsDictionaryElementVM[];
}

export class RouteProviderClassificationsDictionaryVM {
  constructor(list: RouteProviderClassificationsListResponse) {
    this.list = (list || []).map(element => new RouteProviderClassificationsDictionaryElementVM(element));
  }
}

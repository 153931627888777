import React, { useCallback, useState, ReactElement, RefObject, PropsWithChildren } from 'react';
import uniqueId from 'lodash/uniqueId';
import Menu, { type MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { ThemeProvider } from '@mui/material';
import Fade from '@mui/material/Fade';

import { useMuiStyles } from '@/styles/hooks';
import { dropdownTheme } from '@/styles/dropdown-style';

const DROPDOWN_ID_PREFIX = 'dropdown-';

export type OpenButtonComponent = (onClick: () => void) => ReactElement;
type MenuProps = Omit<MuiMenuProps, 'open' | 'anchorEl' | 'TransitionComponent' | 'getContentAnchorEl' | 'anchorOrigin'> & {
  anchorOrigin?: Partial<MuiMenuProps['anchorOrigin']>;
};

type Props<E extends HTMLElement> = PropsWithChildren<{
  openButton: OpenButtonComponent;
  menuProps: MenuProps;
  openingRef: RefObject<E>;
}>;

export const Dropdown = <E extends HTMLElement>({ openButton, openingRef, menuProps: { id, anchorOrigin, ...menuProps }, children }: Props<E>) => {
  const [ID] = useState(id ?? uniqueId(DROPDOWN_ID_PREFIX));
  const [isOpen, setOpen] = useState<boolean>(false);
  const { styles } = useMuiStyles(dropdownTheme);

  const handleOpen = useCallback(() => setOpen(prev => !prev), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      {openButton(handleOpen)}

      {openingRef.current !== null && (
        <ThemeProvider theme={styles}>
          <Menu
            id={ID}
            anchorEl={openingRef.current}
            keepMounted
            open={isOpen}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
              ...anchorOrigin,
            }}
            {...menuProps}
          >
            {children}
          </Menu>
        </ThemeProvider>
      )}
    </>
  );
};

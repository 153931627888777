import { createSelector } from 'reselect';

import { type RoutesListVM } from '@/features/content/models/routes';
import { type HistoryChangesListVM } from '@/models/history-changes';
import { getDataStatus } from '@/common/utils';
import { type routesReducer } from './reducers';

type RouteList = routesReducer['routes']['list'];
type MakeGetRouteListData = RoutesListVM['list'];
type MakeGetHasRouteListNextPage = RoutesListVM['hasNextPage'];
type MakeGetRouteListFilters = RouteList['params'];
type MakeGetRoutesListPaginationData = RoutesListVM['listPaginationData'] | undefined;
type MakeGetRouteListLength = { length: number };

const getRouteList = (state: routesReducer) => state.routes.list;

export const makeGetRouteList = () =>
  createSelector<routesReducer, RouteList, MakeGetRouteListData>(getRouteList, ({ data }) => (data === undefined ? [] : data.list));

export const makeGetHasRouteListNextPage = () =>
  createSelector<routesReducer, RouteList, MakeGetHasRouteListNextPage>(getRouteList, ({ data }) => data?.hasNextPage || false);

export const makeGetRouteListFilters = () => createSelector<routesReducer, RouteList, MakeGetRouteListFilters>(getRouteList, ({ params }) => params);

export const makeGetRouteListLength = () =>
  createSelector<routesReducer, RouteList, MakeGetRouteListLength>(getRouteList, ({ data }) => ({ length: (data?.list || []).length }));

export const makeGetRouteListStatus = () => createSelector<routesReducer, RouteList, ReturnType<typeof getDataStatus>>(getRouteList, getDataStatus);

export const makeGetRoutesListPaginationData = () =>
  createSelector<routesReducer, RouteList, MakeGetRoutesListPaginationData>(getRouteList, ({ data }) => data?.listPaginationData);

const getRouteDetails = (state: routesReducer) => state.routes.details;

type RouteDetails = routesReducer['routes']['details'];
type MakeGetRouteDetailsData = RouteDetails['data'];

export const makeGetRouteDetailsData = () => createSelector<routesReducer, RouteDetails, MakeGetRouteDetailsData>(getRouteDetails, ({ data }) => data);

export const makeGetRouteDetailsStatus = () => createSelector<routesReducer, RouteDetails, ReturnType<typeof getDataStatus>>(getRouteDetails, getDataStatus);

const getRoutesBulkChangeList = (state: routesReducer) => state.routes.bulkChangeList;

type RoutesBulkChangeList = routesReducer['routes']['bulkChangeList'];
type MakeGetRoutesBulkChangeList = RoutesBulkChangeList['list'];

export const makeGetRoutesBulkChangeList = () =>
  createSelector<routesReducer, RoutesBulkChangeList, MakeGetRoutesBulkChangeList>(getRoutesBulkChangeList, ({ list }) => list);

const getRoutesTotalCount = (state: routesReducer) => state.routes.totalCount;

type RoutesTotalCount = routesReducer['routes']['totalCount'];
type MakeGetRoutesTotalCountData = RoutesTotalCount['data'];

export const makeGetRoutesTotalCountData = () =>
  createSelector<routesReducer, RoutesTotalCount, MakeGetRoutesTotalCountData>(getRoutesTotalCount, ({ data }) => data);

export const makeGetRoutesTotalCountStatus = () =>
  createSelector<routesReducer, RoutesTotalCount, ReturnType<typeof getDataStatus>>(getRoutesTotalCount, getDataStatus);

const getRouteThumbnail = (state: routesReducer) => state.routes.thumbnail;

type RouteThumbnail = routesReducer['routes']['thumbnail'];
type MakeGetRouteThumbnailData = RouteThumbnail['data'];

export const makeGetRouteThumbnailData = () => createSelector<routesReducer, RouteThumbnail, MakeGetRouteThumbnailData>(getRouteThumbnail, ({ data }) => data);
export const makeGetRouteThumbnailStatus = () =>
  createSelector<routesReducer, RouteThumbnail, ReturnType<typeof getDataStatus>>(getRouteThumbnail, getDataStatus);

type HistoryChanges = routesReducer['routes']['historyChanges'];
type MakeGetHistoryChangesData = HistoryChangesListVM['list'];
type MakeGetHistoryChangesParams = HistoryChanges['params'];
type MakeGetHistoryChangesNextPage = HistoryChangesListVM['pagination'];

const getHistoryChanges = (state: routesReducer) => state.routes.historyChanges;

export const makeGetHistoryChanges = () =>
  createSelector<routesReducer, HistoryChanges, MakeGetHistoryChangesData>(getHistoryChanges, ({ data }) => (data === undefined ? [] : data.list));
export const makeGetHistoryChangesNextPage = () =>
  createSelector<routesReducer, HistoryChanges, MakeGetHistoryChangesNextPage>(getHistoryChanges, ({ data }) => data?.pagination);
export const makeGetHistoryChangesStatus = () =>
  createSelector<routesReducer, HistoryChanges, ReturnType<typeof getDataStatus>>(getHistoryChanges, getDataStatus);
export const makeGetHistoryChangesPagination = () =>
  createSelector<routesReducer, HistoryChanges, MakeGetHistoryChangesParams>(getHistoryChanges, ({ params }) => params);

import { combineReducers } from 'redux';

import { FeatureStateType } from '@/store/helpers';
import { organisationsDictionaryReducer } from './dictionary';
import { organisationsListReducer } from './list';
import { organisationDetailsReducer } from './details';
import { statusDictionaryReducer } from './statuses';
import { historyChangesReducer } from './history-changes';

export type organisationsReducer = FeatureStateType<typeof organisationsReducer>;
export const organisationsReducer = {
  organisations: combineReducers({
    ...organisationsDictionaryReducer,
    ...organisationsListReducer,
    ...organisationDetailsReducer,
    ...statusDictionaryReducer,
    ...historyChangesReducer,
  }),
};

import { createReducer } from 'typesafe-actions';

import { type RouteProviderClassificationsDictionaryVM } from '@/models/route-provider-classification';
import { getDictionaryInitialState, DictionaryState, dictionaryReducerHelpers } from '@/common/utils/store';
import { FeatureStateType } from '../helpers';
import { routeProviderClassificationsActions } from './actions';

type routeProviderClassificationsState = DictionaryState<RouteProviderClassificationsDictionaryVM>;

const INITIAL_STATE: routeProviderClassificationsState = getDictionaryInitialState<RouteProviderClassificationsDictionaryVM>();

const reducer = createReducer<routeProviderClassificationsState, routeProviderClassificationsActions>(INITIAL_STATE)
  .handleAction(routeProviderClassificationsActions.request, state => dictionaryReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(routeProviderClassificationsActions.silentRequest, state => dictionaryReducerHelpers.silentRequest(INITIAL_STATE, state))
  .handleAction(routeProviderClassificationsActions.cancel, state => dictionaryReducerHelpers.rejected(INITIAL_STATE, state))
  .handleAction(routeProviderClassificationsActions.success, (_, { payload }) => dictionaryReducerHelpers.success(INITIAL_STATE, payload))
  .handleAction(routeProviderClassificationsActions.failure, (state, { payload }) => dictionaryReducerHelpers.failure(INITIAL_STATE, payload));

export const routeProviderClassificationsReducer = { routeProviderClassifications: reducer };
export type routeProviderClassificationsReducer = FeatureStateType<typeof routeProviderClassificationsReducer>;

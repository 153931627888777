import { createSelector } from 'reselect';

import { getDataStatus } from '@/common/utils';
import { type OrganisationListVM } from '@/models/organisations';
import { type HistoryChangesListVM } from '@/models/history-changes';
import { type organisationsReducer } from './reducers';

type OrganisationsDictionary = organisationsReducer['organisations']['dictionary'];
type MakeGetOrganisationsDictionaryData = organisationsReducer['organisations']['dictionary']['data'];

const getOrganisationsDictionary = (state: organisationsReducer) => state.organisations.dictionary;

export const makeGetOrganisationsDictionaryData = () =>
  createSelector<organisationsReducer, OrganisationsDictionary, MakeGetOrganisationsDictionaryData>(getOrganisationsDictionary, ({ data }) => data);

export const makeGetOrganisationsDictionaryStatus = () =>
  createSelector<organisationsReducer, OrganisationsDictionary, ReturnType<typeof getDataStatus>>(getOrganisationsDictionary, getDataStatus);

type OrganisationsList = organisationsReducer['organisations']['list'];
type MakeGetOrganisationsListData = OrganisationListVM['list'];
type MakeGetOrganisationsListFilters = OrganisationsList['params'];
type MakeGetOrganisationsListPaginationData = OrganisationListVM['listPaginationData'] | undefined;

const getOrganisationsList = (state: organisationsReducer) => state.organisations.list;

export const makeGetOrganisationsListData = () =>
  createSelector<organisationsReducer, OrganisationsList, MakeGetOrganisationsListData>(getOrganisationsList, ({ data }) =>
    data === undefined ? [] : data.list
  );

export const makeGetOrganisationsListStatus = () =>
  createSelector<organisationsReducer, OrganisationsList, ReturnType<typeof getDataStatus>>(getOrganisationsList, getDataStatus);

export const makeGetOrganisationsListFilters = () =>
  createSelector<organisationsReducer, OrganisationsList, MakeGetOrganisationsListFilters>(getOrganisationsList, ({ params }) => params);

export const makeGetOrganisationsListPaginationData = () =>
  createSelector<organisationsReducer, OrganisationsList, MakeGetOrganisationsListPaginationData>(getOrganisationsList, ({ data }) => data?.listPaginationData);

const getOrganisationDetails = (state: organisationsReducer) => state.organisations.details;

type OrganisationDetails = organisationsReducer['organisations']['details'];
type MakeGetOrganisationDetailsData = OrganisationDetails['data'];

export const makeGetOrganisationDetailsData = () =>
  createSelector<organisationsReducer, OrganisationDetails, MakeGetOrganisationDetailsData>(getOrganisationDetails, ({ data }) => data);

export const makeGetOrganisationDetailsStatus = () =>
  createSelector<organisationsReducer, OrganisationDetails, ReturnType<typeof getDataStatus>>(getOrganisationDetails, getDataStatus);

type OrganisationStatusesDictionary = organisationsReducer['organisations']['statuses'];
type MakeGetOrganisationStatusesDictionaryData = organisationsReducer['organisations']['statuses']['data'];

const getOrganisationStatusesDictionary = (state: organisationsReducer) => state.organisations.statuses;

export const makeGetOrganisationStatusesDictionaryData = () =>
  createSelector<organisationsReducer, OrganisationStatusesDictionary, MakeGetOrganisationStatusesDictionaryData>(
    getOrganisationStatusesDictionary,
    ({ data }) => data
  );

export const makeGetOrganisationStatusesDictionaryStatus = () =>
  createSelector<organisationsReducer, OrganisationStatusesDictionary, ReturnType<typeof getDataStatus>>(getOrganisationStatusesDictionary, getDataStatus);

type HistoryChanges = organisationsReducer['organisations']['historyChanges'];
type MakeGetHistoryChangesData = HistoryChangesListVM['list'];
type MakeGetSHistoryChangesParams = HistoryChanges['params'];
type MakeGetHistoryChangesNextPage = HistoryChangesListVM['pagination'];

const getHistoryChanges = (state: organisationsReducer) => state.organisations.historyChanges;

export const makeGetHistoryChanges = () =>
  createSelector<organisationsReducer, HistoryChanges, MakeGetHistoryChangesData>(getHistoryChanges, ({ data }) => (data === undefined ? [] : data.list));
export const makeGetHistoryChangesNextPage = () =>
  createSelector<organisationsReducer, HistoryChanges, MakeGetHistoryChangesNextPage>(getHistoryChanges, ({ data }) => data?.pagination);
export const makeGetHistoryChangesStatus = () =>
  createSelector<organisationsReducer, HistoryChanges, ReturnType<typeof getDataStatus>>(getHistoryChanges, getDataStatus);
export const makeGetHistoryChangesPagination = () =>
  createSelector<organisationsReducer, HistoryChanges, MakeGetSHistoryChangesParams>(getHistoryChanges, ({ params }) => params);

import React, { FC, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import uniqueId from 'lodash/uniqueId';

import { IconButton, IconButtonLink } from '@/common/components/icon-button';
import { contentRoutePaths, contentRoutes } from '@/core/navigation';
import { Dialog } from '@/common/components/dialog';
import { ProtectedContent } from '@/common/components/protected-content';
import { makeGetRouteListFilters, routesActions } from '@/store/routes';
import { makeGetUserStatus } from '@/store/authentication';
import { toast } from '@/core/services/toast';
import { CommonError } from '@/common/utils';
import { getOSMapsRouteUrl, deleteRoute, restoreRoutes } from '../../../../services/routes';
import { style } from './styles';

export const ROUTE_DETAILS_ID = uniqueId('ROUTE_DETAILS');
export const ROUTE_OS_MAPS_ID = uniqueId('ROUTE_OS_MAPS');

const getUserStatus = makeGetUserStatus();
const getRouteListFilters = makeGetRouteListFilters();

type Props = {
  id: string;
  deleteDisabled: boolean;
  elementId?: number;
  disableAll?: boolean;
  routeName: string;
  isDeleted: boolean;
};

export const RoutesActions: FC<Props> = ({ id, deleteDisabled, elementId, disableAll, routeName, isDeleted }) => {
  const destroy$ = useRef(new Subject());
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);
  const [isRestoringInProgress, setIsRestoringInProgress] = useState<boolean>(false);
  const { currentRegion } = useSelector(getUserStatus);
  const routeListFilters = useSelector(getRouteListFilters);
  const OSMapsRouteDetailsUrl = useMemo(() => getOSMapsRouteUrl(id), [id]);

  const toggleDeleteDialog = useCallback(() => setIsDialogOpen(state => !state), []);

  const confirmDeleteRoute = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteRoute(id, currentRegion?.url)
      .pipe(takeUntil(destroy$.current))
      .subscribe(response => {
        setIsDeleteInProgress(false);
        if (response instanceof CommonError) {
          toast.show({ type: 'error', text: 'error.deleteRoute' });
        } else {
          setIsDialogOpen(false);
          toast.show({ text: t('success.deleteRoute'), type: 'success' });
          dispatch(routesActions.list.request({ params: { currentRegionUrl: currentRegion?.url, page: 0 }, options: { mergeWithPrevProps: true } }));
          dispatch(
            routesActions.totalCount.request({
              params: routeListFilters,
            })
          );
        }
      });
  }, [id, currentRegion?.url, t, dispatch, routeListFilters]);

  const restoreRoute = useCallback(() => {
    setIsRestoringInProgress(true);
    restoreRoutes([id], currentRegion?.url)
      .pipe(takeUntil(destroy$.current))
      .subscribe(response => {
        setIsRestoringInProgress(false);
        if (response instanceof CommonError) {
          toast.show({ type: 'error', text: 'error.restoreRoute' });
        } else {
          toast.show({ text: t('success.restoreRoute'), type: 'success' });
          dispatch(routesActions.list.request({ params: { currentRegionUrl: currentRegion?.url, page: 0 }, options: { mergeWithPrevProps: true } }));
          dispatch(
            routesActions.totalCount.request({
              params: routeListFilters,
            })
          );
        }
      });
  }, [id, currentRegion?.url, t, dispatch, routeListFilters]);

  useEffect(
    () => () => {
      destroy$.current.next();
      destroy$.current.complete();
    },
    []
  );

  return (
    <div css={style} onClick={event => event.stopPropagation()}>
      <Dialog
        open={isDialogOpen}
        onClose={toggleDeleteDialog}
        onConfirm={confirmDeleteRoute}
        title={t('dialog.deleteRoute.title')}
        dialogTheme='error'
        confirmLabel={t('button.delete')}
      >
        <div>{t('dialog.deleteRoute.list.question', { name: routeName })}</div>
      </Dialog>

      {!isDeleted ? (
        <>
          <IconButtonLink
            external
            id={`${ROUTE_OS_MAPS_ID}${elementId}`}
            link={OSMapsRouteDetailsUrl}
            target='_blank'
            iconName='mdi-map'
            className='action-button action-button--link'
            disabled={disableAll}
          />

          <IconButton
            onClick={toggleDeleteDialog}
            className='action-button'
            iconName='mdi-delete'
            theme='error'
            disabled={isDeleteInProgress || deleteDisabled || disableAll}
          />
        </>
      ) : (
        <ProtectedContent subject='ROUTES' scope='RESTORE'>
          <IconButton
            onClick={restoreRoute}
            className='action-button'
            iconName='mdi-published-with-changes'
            theme='primary'
            disabled={isRestoringInProgress || disableAll}
          />
        </ProtectedContent>
      )}

      <IconButtonLink
        link={contentRoutePaths[contentRoutes.ROUTES_DETAILS].replace(/:id/g, id)}
        className='action-button'
        iconName='mdi-pencil'
        theme='primary'
        disabled={isDeleteInProgress || disableAll}
        id={`${ROUTE_DETAILS_ID}${elementId}`}
      />
    </div>
  );
};

import { ActionType, Action } from 'typesafe-actions';

import { createDictionaryActions } from '@/common/utils/store';
import { type RouteProviderClassificationsDictionaryVM } from '@/models/route-provider-classification';

export const routeProviderClassificationsActions = createDictionaryActions(
  '[ROUTE PROVIDER CLASSIFICATIONS DICTIONARY] FETCH REQUEST',
  '[ROUTE PROVIDER CLASSIFICATIONS DICTIONARY] FETCH SUCCESS',
  '[ROUTE PROVIDER CLASSIFICATIONS DICTIONARY] FETCH FAILURE',
  '[ROUTE PROVIDER CLASSIFICATIONS DICTIONARY] FETCH CANCEL',
  '[ROUTE PROVIDER CLASSIFICATIONS DICTIONARY] FETCH SILENT_REQUEST'
)<undefined, RouteProviderClassificationsDictionaryVM>();

export type routeProviderClassificationsActions = Action | ActionType<typeof routeProviderClassificationsActions>;

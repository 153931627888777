import React, { ReactElement, InputHTMLAttributes, ChangeEvent, useCallback, ReactNode } from 'react';
import uniqueId from 'lodash/uniqueId';
import cn from 'classnames';

import { useInstanceValue } from '@/common/utils/functions';
import { useStyles } from '@/styles/hooks';
import { checkBoxStyles } from './styles';

type Status = 'error';
export type Value = string | number;
export type CheckedChangedTarget<T extends Value = Value> = { value: T; checked: boolean };

export type CheckboxProps<T extends Value = Value> = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | ReactNode;
  labelIcon?: ReactElement;
  status?: Status;
  onCheckedChange?: (target: CheckedChangedTarget<T>) => void;
  inline?: boolean;
};

export const Checkbox = <T extends Value = Value>({
  status,
  label,
  labelIcon,
  onChange,
  onCheckedChange,
  inline = false,
  className,
  ...props
}: CheckboxProps<T>) => {
  const id = useInstanceValue(() => uniqueId('checkbox'));
  const { styles } = useStyles(checkBoxStyles);

  const handleOnChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      onCheckedChange?.({
        checked: target.checked,
        value: (typeof props.value === 'number' ? +target.value : target.value) as T,
      }),
    [onCheckedChange, props.value]
  );

  return (
    <div css={styles} className={cn([className, { status, inline }])}>
      <input id={id.current} className='checkbox-control' type='checkbox' onChange={onCheckedChange ? handleOnChange : onChange} {...props} />

      <label htmlFor={id.current} className='label'>
        <span className='label__mark' />
        {label !== undefined && (
          <span className='label__text'>
            {label}
            {labelIcon}
          </span>
        )}
      </label>
    </div>
  );
};

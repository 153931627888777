import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getRouteProviderClassificationsData } from '@/features/content/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { routeProviderClassificationsActions } from './actions';

const getRouteProviderClassificationsList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(routeProviderClassificationsActions.request)),
    switchMap(() => {
      const state = state$.value.routeProviderClassifications;
      return dictionaryEffectHelper(() => getRouteProviderClassificationsData(), state, routeProviderClassificationsActions);
    })
  );

export const routeProviderClassificationsEpics = [getRouteProviderClassificationsList];

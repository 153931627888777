import React, { FC, useEffect, useRef, HTMLAttributes, ReactElement, ReactEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialModal, { type ModalProps as MaterialModalProps } from '@mui/material/Modal';

import { useStyles } from '@/styles/hooks';
import { Icon } from '../icon';
import { Button } from '../button';
import { dialogStyles } from './styles';

const DEFAULT_CONFIRM_LABEL = 'button.ok';
const DEFAULT_CANCEL_LABEL = 'button.cancel';

export type DialogTheme = 'confirm' | 'error';

export type DialogProps = MaterialModalProps & {
  onClose: () => void | ReactEventHandler<{}>;
  onConfirm?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmDisabled?: boolean;
  title?: string;
  dialogTheme?: DialogTheme;
  getDialogElement?: (element: HTMLDivElement | null) => void;
  dialogProps?: HTMLAttributes<HTMLDivElement>;
  hideCloseButton?: boolean;
  hideConfirmButton?: boolean;
  additionalButtons?: ReactElement[];
};

export const Dialog: FC<DialogProps> = ({
  children,
  onClose,
  onConfirm,
  title,
  dialogTheme = 'confirm',
  confirmLabel = DEFAULT_CONFIRM_LABEL,
  cancelLabel = DEFAULT_CANCEL_LABEL,
  confirmDisabled = false,
  getDialogElement,
  dialogProps,
  hideCloseButton,
  hideConfirmButton,
  additionalButtons,
  ...props
}) => {
  const [t] = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { styles } = useStyles(dialogStyles);

  useEffect(() => getDialogElement?.(ref.current));

  return (
    <MaterialModal onClose={onClose} {...props} css={styles}>
      <div ref={ref} className='dialog' {...dialogProps}>
        <button className='close-button' onClick={onClose}>
          <Icon name='mdi-close' className='button-icon' />
        </button>
        <div className='dialog-content'>
          {title && <div className='title'>{title}</div>}
          {children}
        </div>
        {(onConfirm || additionalButtons) && (
          <div className='buttons'>
            <div>{additionalButtons}</div>
            {(onConfirm || hideConfirmButton) && (
              <div className={'buttons__common'}>
                {!hideCloseButton && (
                  <Button onClick={onClose} theme='secondary'>
                    {t(cancelLabel)}
                  </Button>
                )}
                {!hideConfirmButton && (
                  <Button onClick={onConfirm} disabled={confirmDisabled} theme={dialogTheme === 'confirm' ? 'primary' : 'error'}>
                    {t(confirmLabel)}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </MaterialModal>
  );
};

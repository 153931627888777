import { FormikProps } from 'formik';
import React, { FC, InputHTMLAttributes, useCallback, useMemo, useState } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { useDerivedState } from '@/common/utils';
import { ControlContainer, ControlContainerProps } from './components/control-container';
import { DropContainer, DropContainerProps } from './components/drop-container';
import { PreviewPlaceholderProps } from './components/preview-placeholder';
import { FileInputValueArray, FileSize, FileSizeConstructor, FileType } from './models';
import { isCustomFile, FileInputPreset, OTHER_MIME_TYPE } from './constants';
import { styles } from './styles';

export type FilesChangeFunction<T = File> = (files: T[]) => void;

export type FileInputProps = PreviewPlaceholderProps &
  DropContainerProps &
  Partial<ControlContainerProps> & {
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    name?: string;
    form?: FormikProps<any>;
    onChange?: FilesChangeFunction;
    onFileChange?: FilesChangeFunction;
    onReject?: FilesChangeFunction<FileRejection>;
    dropzoneOptions?: DropzoneOptions;
    value?: FileInputValueArray;
    max?: FileSizeConstructor;
    preset?: FileType;
    editorCardMode?: boolean;
  };

type OnDrop<T extends File> = (acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;

export const FileInput: FC<FileInputProps> = ({
  value,
  disabled,
  form,
  name,
  multiple = false,
  fileType: overrideFileType,
  accept: overrideAccept,
  preset = FileType.Other,
  imageSrc,
  fileName,
  max,
  alterMessage,
  buttonProps,
  onChange,
  onFileChange,
  onReject,
  onDownload,
  dropzoneOptions,
  large,
  higher,
  inputProps,
  downloadIconProps,
  controlContainerProps,
  infoSectionProps,
  dropZoneRootProps,
  dropContainerWrapperProps,
  imagePreviewProps,
  editorCardMode,
}) => {
  const [coverImageUrl, setCoverImageUrl] = useState<string | undefined>();
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>();
  const [hasValue, setHasValue] = useDerivedState<boolean>(!!value?.[0]);

  const showDownload = useMemo(() => !!value?.[0] && isCustomFile(value[0]), [value]);

  const { accept, fileType, mimeType } = useMemo(
    () => ({
      accept: overrideAccept || FileInputPreset[preset].accept,
      fileType: overrideFileType || FileInputPreset[preset].fileType,
      mimeType: OTHER_MIME_TYPE || FileInputPreset[preset].mimeType,
    }),
    [preset, overrideAccept, overrideFileType]
  );

  const maxSize = useMemo(() => (max ? (typeof max === 'number' ? new FileSize(max) : new FileSize(max.size, max.unit)) : undefined), [max]);

  const handleOnChange = useCallback(
    (files: File[] | FileList) => {
      if (!files || !files[0]) return;

      if (fileType === FileType.Image && !multiple) setCoverImageUrl(URL.createObjectURL(files[0]));
      if (!multiple) setUploadedFileName(files[0].name);
      setHasValue(true);

      if (form && name) {
        form.setFieldTouched(name);
        form.setFieldValue(name, files);
      }

      if (!form) onChange?.(Array.from(files));

      onFileChange?.(Array.from(files));
    },
    [form, name, multiple, fileType, onChange, onFileChange]
  );

  const handleOnReject = useCallback(
    (fileRejections: FileRejection[]) => {
      if (!fileRejections.length) return;

      if (onReject) return onReject(fileRejections);

      if (form && name) {
        form.setFieldValue(
          name,
          fileRejections.map(rejected => rejected.file)
        );
        form.setFieldTouched(name, true, true);
      }
    },
    [form, name, onReject]
  );

  const onDrop = useCallback<OnDrop<File>>(
    (acceptedFiles, fileRejections) => {
      handleOnChange(acceptedFiles);
      handleOnReject(fileRejections);
    },
    [handleOnChange, handleOnReject]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled,
    multiple,
    accept: accept && { [mimeType]: accept },
    maxSize: maxSize?.Bytes,
    ...(dropzoneOptions || {}),
    onDrop,
  });

  const getPreviewProps = useCallback(() => {
    const file = value?.[0] && (isCustomFile(value[0]) ? { name: value[0].name, path: value[0].path } : { name: value[0].name, path: '' });

    switch (fileType) {
      case FileType.Image:
        if (coverImageUrl || uploadedFileName)
          return {
            imageSrc: coverImageUrl,
            fileName: uploadedFileName,
          };
        if (file && (file.name || file.path))
          return {
            imageSrc: file.path,
            fileName: file.name,
          };
      case FileType.Map:
        if (file?.name)
          return {
            fileName: file.name,
          };

        if (imageSrc)
          return {
            imageSrc,
          };
      case FileType.Other:
      default:
        if (uploadedFileName)
          return {
            fileName: uploadedFileName,
          };
        if (file && file.name)
          return {
            fileName: file.name,
          };
        return {
          fileName,
        };
    }
  }, [value, imageSrc, fileName, fileType, coverImageUrl, uploadedFileName]);

  return (
    <div css={styles}>
      <DropContainer
        getRootProps={getRootProps}
        {...getPreviewProps()}
        fileType={fileType}
        showDownload={showDownload}
        isDragActive={isDragActive}
        disabled={disabled}
        large={large}
        higher={higher}
        multiple={multiple}
        onDownload={onDownload}
        downloadIconProps={downloadIconProps}
        dropZoneRootProps={dropZoneRootProps}
        dropContainerWrapperProps={dropContainerWrapperProps}
        imagePreviewProps={imagePreviewProps}
        editorCardMode={editorCardMode}
      >
        <input {...getInputProps({ name: name || 'file-input', ...inputProps })} />
      </DropContainer>

      <ControlContainer
        buttonProps={{ onClick: open, ...buttonProps }}
        hasValue={hasValue}
        max={maxSize?.MegaBytes}
        accept={accept}
        alterMessage={alterMessage}
        multiple={multiple}
        disabled={disabled}
        controlContainerProps={controlContainerProps}
        infoSectionProps={infoSectionProps}
        editorCardMode={editorCardMode}
      />
    </div>
  );
};

import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getOrganisationsDictionaryData, getOrganisationStatusDictionaryData } from '@/features/accounts/services/dictionaries';
import { getOrganisationsDetailsData, getOrganisationsListData } from '@/features/accounts/services/organisations';
import { dictionaryEffectHelper, CommonError, untilLocationChange } from '@/common/utils';
import { getHistoryChangesData } from '@/common/services';
import { organisationsReducer } from './reducers';
import { organisationsActions } from './actions';

const getOrganisationsList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(organisationsActions.list.request)),
    switchMap(() =>
      getOrganisationsListData(state$.value.organisations.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return organisationsActions.list.failure(response);
          }

          return organisationsActions.list.success(response);
        })
      )
    )
  );

const getOrganisationDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(organisationsActions.details.request)),
    switchMap(action =>
      getOrganisationsDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return organisationsActions.details.failure(response);
            }

            return organisationsActions.details.success(response);
          })
        )
    )
  );

const getOrganisationsDictionary: Epic<organisationsActions, organisationsActions, organisationsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(organisationsActions.dictionary.request)),
    switchMap(() => {
      const state = state$.value.organisations.dictionary;
      return dictionaryEffectHelper(() => getOrganisationsDictionaryData(), state, organisationsActions.dictionary);
    })
  ) as any;

const getOrganisationStatusDictionary: Epic<organisationsActions, organisationsActions, organisationsReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(organisationsActions.statusesDictionary.request)),
    switchMap(() => {
      const state = state$.value.organisations.statuses;
      return dictionaryEffectHelper(() => getOrganisationStatusDictionaryData(), state, organisationsActions.statusesDictionary);
    })
  ) as any;

export const getHistoryChangesEpic: Epic<organisationsActions, organisationsActions, organisationsReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(organisationsActions.historyChanges.request)),
    switchMap(action =>
      getHistoryChangesData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return organisationsActions.historyChanges.failure(response);
          }

          return organisationsActions.historyChanges.success(response);
        })
      )
    )
  );

export const organisationsEpics = [
  getOrganisationsList,
  getOrganisationDetails,
  getOrganisationsDictionary,
  getOrganisationStatusDictionary,
  getHistoryChangesEpic,
];

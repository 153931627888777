import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useStyles } from '@/styles/hooks';
import { datepickerStyles } from './styles';
import { Input } from '../input';

export type DatepickerRef = {
  forceClearInput: () => void;
};

export type DatepickerProps = ReactDatePickerProps & {
  name?: string;
  disabled?: boolean;
  monthMode?: boolean;
  placeholder?: string;
};

type ReactDatePickerRef = ReactDatePicker & {
  clear: () => void;
};

export const Datepicker = forwardRef<DatepickerRef, DatepickerProps>(({ name, disabled, monthMode, placeholder, ...props }, ref) => {
  const [t] = useTranslation();
  const { styles } = useStyles(datepickerStyles);
  const datePickerRef = useRef<ReactDatePickerRef>(null);

  useImperativeHandle(ref, () => ({
    forceClearInput: () => {
      datePickerRef.current?.clear();
    },
  }));

  return (
    <div css={styles}>
      <ReactDatePicker
        ref={datePickerRef}
        {...props}
        name={name}
        placeholderText={placeholder ? placeholder : t('placeholder.chooseDate')}
        showPopperArrow={false}
        showMonthYearPicker={monthMode}
        clearButtonClassName={disabled ? 'clear-button clear-button--disabled' : 'clear-button'}
        disabled={disabled}
        customInput={<Input name={name} />}
      />
    </div>
  );
});

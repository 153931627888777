import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import { GlobalStyles } from '@/styles/global';
import { store } from '@/store';
import { setFormLocalization } from '@/common/components/form/components/validators';
import { createHttpInstances } from './services/http-client';
import { i18n } from './services/i18n';
import { createStorageManagement } from './services/storage-management/storage-management';
import { RootNavigation } from './navigation/root.navigation';
import { createCookieControl } from './services/cookie-control';
import { DataDog } from './services/data-dog';

createHttpInstances(store);
const storageManagement = createStorageManagement(store);
const cookieControl = createCookieControl();
const dataDog = DataDog();

export const Core: FC = () => {
  useEffect(() => {
    cookieControl.init();
    storageManagement.init();
    dataDog.init();
    setFormLocalization();

    return () => storageManagement.clear();
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <GlobalStyles />
        <RootNavigation />
      </I18nextProvider>
    </Provider>
  );
};

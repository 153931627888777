import React, { FC } from 'react';

import { useStyles } from '@/styles/hooks';
import { NavGroup } from '../../../../models';
import { NavigationElement } from '../navigation-element';
import { navigationElementGroupStyles } from './styles';

type Props = {
  data: NavGroup;
};

export const NavigationElementGroup: FC<Props> = ({ data }) => {
  const { styles } = useStyles(navigationElementGroupStyles);

  return (
    <div css={styles}>
      {data.elements.map((element, index) => (
        <NavigationElement key={`navigation-element-${index}`} data={element} />
      ))}
    </div>
  );
};

import React, { useEffect, useCallback, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import isEqual from 'lodash/isEqual';

import { StatusWrapper, StatusWrapperStatus } from '@/common/components/status-wrapper';
import { useInstanceValue } from '@/common/utils/functions';
import { Checkbox, CheckedChangedTarget, Value } from '../checkbox';

const DEBOUNCE_TIME = 1000;

export type Option<T extends Value = Value> = { label: string; value: T };
export type CheckboxGroupChangeFunc<T extends Value = Value> = (value: T[]) => void;

type Props<T extends Value = Value> = {
  value: T[];
  options: Option<T>[];
  onChange: CheckboxGroupChangeFunc<T>;
  disabled?: boolean;
  status?: StatusWrapperStatus;
  minHeight?: number;
};

export const CheckboxGroup = <T extends Value = Value>({ onChange, value, options, status, disabled, minHeight = 90 }: Props<T>) => {
  const [innerValue, setInnerValue] = useState<T[]>(value);

  const onChange$ = useInstanceValue(() => new Subject<T[]>());

  const handleOnChange = useCallback<(t: CheckedChangedTarget<T>) => void>(target => {
    setInnerValue(oldInnerState => {
      let newState: T[] = [...oldInnerState];
      if (target.checked) {
        if (!oldInnerState.includes(target.value)) {
          newState.push(target.value);
        }
      } else if (oldInnerState.includes(target.value)) {
        newState = newState.filter(entity => entity !== target.value);
      }

      onChange$.current.next(newState);
      return newState;
    });
  }, []);

  useEffect(() => {
    if (!isEqual(innerValue, value)) {
      setInnerValue(value);
    }
  }, [value]);

  useEffect(() => {
    const subscription = onChange$.current.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(onChange);

    return () => subscription.unsubscribe();
  }, [value, onChange]);

  return (
    <StatusWrapper status={status || 'success'} minHeight={minHeight}>
      {(options || []).map(entity => (
        <Checkbox
          key={entity.value}
          disabled={disabled}
          value={entity.value}
          label={entity.label}
          checked={innerValue.includes(entity.value)}
          onCheckedChange={handleOnChange}
        />
      ))}
    </StatusWrapper>
  );
};

import { datadogLogs } from '@datadog/browser-logs';

import { getAppVersion, getEnv } from '@/common/utils';

const DD_CLIENT_TOKEN = 'pub2da0dc07c5a8c578bad9d582718d4b59';
const DD_SITE = 'datadoghq.com';

export const DataDog = () => {
  const appVersion = getAppVersion();
  const config = getEnv();

  const init = () => {
    datadogLogs.init({
      clientToken: DD_CLIENT_TOKEN,
      site: DD_SITE,
      env: config.REACT_APP_ENV_NAME,
      version: appVersion,
      service: 'admin-panel',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'],
      sessionSampleRate: 100,
    });
  };

  return { init };
};

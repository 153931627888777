import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getEnv, CommonError } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import { type HistoryChangesListParams, type HistoryChangesListResponse, HistoryChangesListVM } from '@/models/history-changes';
import { HISTORY_CHANGES_PAGE_SIZE } from '../components/history-changes/constants';

const config = getEnv();

const HISTORY_CHANGES_DATA_PATH = `${config.REACT_APP_API_URL}/audit-api/v1/admin/audit-logs`;

export const getHistoryChangesData = (data: HistoryChangesListParams) => {
  const body = {
    targetType: data.query.targetType,
    targetId: data.query.targetId,
    requestContinuation: data.pageParams.requestContinuation,
    nextPageToken: data.query.nextPageToken,
  };

  return httpClient()
    .authorized.get<HistoryChangesListResponse>(
      `${HISTORY_CHANGES_DATA_PATH}?size=${HISTORY_CHANGES_PAGE_SIZE}&targetType=${body.targetType}&targetId=${body.targetId}${
        body.nextPageToken ? `&nextPageToken=${body.nextPageToken}` : ''
      }`
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new HistoryChangesListVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

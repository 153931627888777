import { useState, useEffect, useMemo, useCallback } from 'react';
import { Row } from 'react-table';
import cn from 'classnames';

export type OnRowClickFunc = <T extends {}>(index: number, row?: Row<T>) => void;

export type RowClickProps = {
  onRowClick?: OnRowClickFunc;
  selectedRow?: number;
  isRowSelectStylesVisible?: boolean;
};

export const useRowClick = ({ selectedRow = -1, onRowClick, isRowSelectStylesVisible }: RowClickProps) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(selectedRow);

  useEffect(() => {
    if (selectedRow !== selectedRowIndex) setSelectedRowIndex(selectedRow);
  }, [selectedRow, selectedRowIndex]);

  const isRowClickable = useMemo(() => !!onRowClick, [onRowClick]);

  const handleRowClick = useCallback(
    (index: number, row?: Row<any>) => () => {
      if (isRowClickable) {
        setSelectedRowIndex(index);
        onRowClick!(index, row);
      }
    },
    [isRowClickable, onRowClick]
  );

  return {
    handleRowClick,
    getRowClassName: (index: number) =>
      isRowClickable ? cn({ clickable: isRowClickable, selected: isRowSelectStylesVisible && index === selectedRowIndex }) : undefined,
  };
};

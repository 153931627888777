import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

import { useStyles } from '@/styles/hooks';
import { Icon } from '../icon';
import { iconButtonStyles } from './styles';

export type IconButtonTheme = 'primary' | 'secondary' | 'error';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  iconName: string;
  theme?: IconButtonTheme;
  className?: string;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ iconName, className, theme = 'primary', ...props }, ref) => {
  const { styles } = useStyles(iconButtonStyles);

  return (
    <button css={styles} ref={ref} {...props} className={cn(['icon-button', theme, className])}>
      <Icon name={iconName} className={cn(['icon', `icon--${theme}`])} />
    </button>
  );
});

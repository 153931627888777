import { createTheme, adaptV4Theme } from '@mui/material/styles';

import { Styles, constants, theme } from '@/styles';

export const autocompleteStyle: Styles = {
  '.autocomplete-wrapper': {
    display: 'flex',
    flexDirection: 'row',

    '.MuiInputBase-root': {
      height: 40,
    },

    fieldset: {
      border: `1px solid ${theme.backgroundSixth}`,
    },
  },

  '.autocomplete': {
    width: '100%',
  },

  '.autocomplete-button': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  '.autocomplete-input': {
    position: 'relative',
    zIndex: 0,
  },
};

export const autocompleteListStyle = createTheme(
  adaptV4Theme({
    overrides: {
      MuiAutocomplete: {
        popper: {
          zIndex: 1,
          boxShadow: constants.dialogShadow,

          '& .dark': {
            backgroundColor: '#1d1c1c',
            color: theme.textWhite,
          },

          '.MuiAutocomplete-option': {
            overflow: 'visible !important',
          },
        },

        popperDisablePortal: {
          marginTop: 41,
        },

        popupIndicator: {
          '.icon': {
            color: `${theme.primary}`,
            fontSize: '25px',
            maxHeight: 25,
          },
        },

        clearIndicator: {
          '& > svg': {
            color: `${theme.primary} !important`,
            fontSize: '20px !important',
            maxHeight: 25,
          },
        },

        noOptions: {
          '.dark': {
            color: theme.textWhite,
          },
        },

        listbox: {
          backgroundColor: theme.backgroundPrimary,
          padding: `${constants.offset.xsmall}px 0`,
        },

        paper: {
          backgroundColor: theme.backgroundPrimary,
          margin: 0,
          position: 'relative',

          '& > *': {
            color: `${theme.textPrimary}`,
          },
        },

        option: {
          backgroundColor: theme.backgroundPrimary,

          '& .dark': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },

          '& > *': {
            color: theme.textPrimary,
          },

          '&:hover': {
            color: theme.primaryHover,
          },

          '&[aria-selected="true"]': {
            backgroundColor: `${theme.primary} !important`,
            color: theme.textWhite,
            '&:hover': {
              '& > *': {
                color: theme.primaryHover,
              },
            },
          },

          '&[data-focus="true"]': {
            backgroundColor: theme.backgroundPrimaryHover,

            '& > *': {
              color: theme.primaryHover,
            },
          },
        },
      },
    },
  })
);

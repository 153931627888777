import * as Yup from 'yup';

import { CommonValidationError } from './models';

export const STRING_LOCALE: Yup.LocaleObject['string'] = {
  min: ({ min }) => ({ key: 'validation.string.min', values: { min } } as CommonValidationError),
  max: ({ max }) => ({ key: 'validation.string.max', values: { max } } as CommonValidationError),
};

export const stringValidator = Yup.string().typeError('validation.string.type');

import hexRgb from 'hex-rgb';
import cn, { Mapping } from 'classnames';

import { fontSize } from './constants';

type ClassNameValue = string | number | boolean | Mapping | ClassNameValue[] | null | undefined;

export const rem = (val: number) => `${val / (fontSize.medium || 1)}rem`;

export const rgba = (hex: string, alpha: number) => {
  const rgb = hexRgb(hex, { format: 'array' });
  rgb.pop();
  return `rgba(${rgb}, ${alpha})`;
};

/**
 * @param className primary class name
 * @param subclass list of subclasses to be joined to className, if subclass[index] is some Array, then it will be treated as another className (passed directly to classnames function as another argument)
 * @returns joined class name string
 *
 * @example getClassName('foo', 'bar', { '-baz': true }, ['lorem', { ipsum: true }] ) => 'foo foobar foo-baz lorem ipsum'
 */

export const getClassName = (className: string, ...subclass: ClassNameValue[]) =>
  cn([
    className,
    ...subclass.map(name =>
      name
        ? typeof name === 'object'
          ? Array.isArray(name)
            ? name
            : Object.keys(name).map(key => (name[key] ? className + key : ''))
          : className + name
        : ''
    ),
  ]);

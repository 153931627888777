import { ActionType, Action, createAction } from 'typesafe-actions';

import {
  OrganisationDictionaryVM,
  OrganisationsListParams,
  OrganisationListVM,
  OrganisationDetailsVM,
  OrganisationStatusDictionaryVM,
} from '@/models/organisations';
import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import { HistoryChangesListParams, HistoryChangesListVM } from '@/models/history-changes';

export const organisationsActions = {
  list: createFetchActions('FETCH_ORGANISATIONS_LIST_REQUEST', 'FETCH_ORGANISATIONS_LIST_SUCCESS', 'FETCH_ORGANISATIONS_LIST_FAILURE')<
    OrganisationsListParams,
    OrganisationListVM
  >(),
  clearOrganisationsList: createAction('CLEAR_ORGANISATIONS_LIST')(),
  details: createFetchActions('FETCH_ORGANISATION_DETAILS_REQUEST', 'FETCH_ORGANISATION_DETAILS_SUCCESS', 'FETCH_ORGANISATION_DETAILS_FAILURE')<
    string,
    OrganisationDetailsVM
  >(),
  historyChanges: createFetchActions(
    'FETCH_ORGANISATIONS_HISTORY_CHANGES_LIST_REQUEST',
    'FETCH_ORGANISATIONS_HISTORY_CHANGES_LIST_SUCCESS',
    'FETCH_ORGANISATIONS_HISTORY_CHANGES_LIST_FAILURE'
  )<HistoryChangesListParams, HistoryChangesListVM>(),
  clearHistoryChanges: createAction('CLEAR_ORGANISATIONS_HISTORY_CHANGES')(),
  dictionary: createDictionaryActions(
    'FETCH_ORGANISATIONS_DICTIONARY_REQUEST',
    'FETCH_ORGANISATIONS_DICTIONARY_SUCCESS',
    'FETCH_ORGANISATIONS_DICTIONARY_FAILURE',
    'FETCH_ORGANISATIONS_DICTIONARY_CANCEL',
    'FETCH_ORGANISATIONS_DICTIONARY_SILENT_REQUEST'
  )<undefined, OrganisationDictionaryVM>(),
  statusesDictionary: createDictionaryActions(
    'FETCH_ORGANISATION_STATUS_DICTIONARY_REQUEST',
    'FETCH_ORGANISATION_STATUS_DICTIONARY_SUCCESS',
    'FETCH_ORGANISATION_STATUS_DICTIONARY_FAILURE',
    'FETCH_ORGANISATION_STATUS_DICTIONARY_CANCEL',
    'FETCH_ORGANISATION_STATUS_DICTIONARY_SILENT_REQUEST'
  )<undefined, OrganisationStatusDictionaryVM>(),
  clearOrganisationForm: createAction('CLEAR_ORGANISATION_FORM')(),
};

export type organisationsActions = Action | ActionType<typeof organisationsActions>;

import { createReducer } from 'typesafe-actions';

import { HistoryChangesVM, type HistoryChangesListVM } from '@/models/history-changes';
import { type FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { type FeatureStateType } from '../../helpers';
import { routesActions } from '../actions';

export type historyChangesState = FetchState<HistoryChangesListVM>;

const INITIAL_STATE: historyChangesState = getFetchInitialState<HistoryChangesListVM>({ size: 5 });

export const historyChangesSuccessReducer = <State extends FetchState, Payload extends HistoryChangesListVM>(
  initialState: State,
  state: State,
  payload: Payload
) => {
  const newState = fetchReducerHelpers.success(initialState, state, payload);
  const { list } = payload;

  const newList = [...(state.data?.list ? state.data?.list : []), ...list];

  const updatedListData = historyChangesTotalScoreFieldCleanUpHelper(newList);

  return {
    ...newState,
    data: newState.data === undefined ? undefined : { ...newState.data, list: updatedListData },
  };
};

const reducer = createReducer<historyChangesState, routesActions>(INITIAL_STATE)
  .handleAction(routesActions.historyChanges.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(routesActions.historyChanges.success, (state, { payload }) => historyChangesSuccessReducer(INITIAL_STATE, state, payload))
  .handleAction(routesActions.historyChanges.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(routesActions.clearHistoryChanges, () => ({ ...INITIAL_STATE }));

export const historyChangesReducer = { historyChanges: reducer };
export type historyChangesReducer = FeatureStateType<typeof historyChangesReducer>;

function historyChangesTotalScoreFieldCleanUpHelper(historyOfChanges: HistoryChangesVM[]): HistoryChangesVM[] {
  const totalScoreRegEx = /totalScore=([\d.]+)/;

  return historyOfChanges.map(item => {
    item.whatChanged = item.whatChanged.map(change => {
      if (change.fieldName === 'Quality rating') {
        let newValue = change.change.newValue;
        let oldValue = change.change.oldValue;
        const newValueMatch = newValue.match(totalScoreRegEx);
        // oldValue can be undefined in the first iteration, optional chaining operator is on purpose here
        const oldValueMatch = oldValue?.match(totalScoreRegEx);

        if (newValueMatch) {
          const totalScore = `${parseFloat(newValueMatch[1]) * 100}%`;

          newValue = `TOTAL_SCORE=${totalScore}`;
        }
        if (oldValueMatch) {
          const totalScore = `${parseFloat(oldValueMatch[1]) * 100}%`;

          oldValue = `TOTAL_SCORE=${totalScore}`;
        }

        return {
          ...change,
          change: {
            newValue,
            oldValue,
          },
        };
      }
      return change;
    });

    return item;
  });
}

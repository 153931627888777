import dayjs from 'dayjs';

export function getPastDate(amount: number, type: dayjs.ManipulateType) {
  return dayjs().subtract(amount, type).toDate();
}

export function getLastYear() {
  return dayjs().subtract(1, 'y').get('year');
}

export function getCurrentYear() {
  return dayjs().get('y');
}

export function getCurrentMonth() {
  return dayjs().get('month') + 1;
}

export function getFirstDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getLastMonthStart() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

export function getLastMonthEnd() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 0);
}

export function getCurrentQuarterStart() {
  const quarter = Math.floor(dayjs().get('M') / 3);
  return new Date(dayjs().get('y'), quarter * 3, 1);
}

export function getCurrentQuarterEnd() {
  const quarter = Math.floor(dayjs().get('M') / 3);
  return new Date(dayjs().get('y'), quarter * 3 + 3, 0);
}

export function getLastQuarterStart() {
  const quarter = Math.floor(dayjs().get('M') / 3);
  return new Date(dayjs().get('y'), quarter * 3 - 3, 1);
}

export function getLastQuarterEnd() {
  return new Date(getLastQuarterStart().getFullYear(), getLastQuarterStart().getMonth() + 3, 0);
}

import React, { FC } from 'react';
import cn from 'classnames';

import { Field, FieldProps } from '../field';
import { SelectOption } from '../select';
import { UnitInput } from './unit-input';

type FieldUnitInputProps = Omit<FieldProps, 'onChange' | 'value' | 'children'> & {
  options: SelectOption[];
};

export const FieldUnitInput: FC<FieldUnitInputProps> = ({ name, className, label, labelIcon, isFocusOnLabelEnabled, ...props }) => (
  <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={false} className={className}>
    {({ field, meta, form }) => {
      const inputClassName = cn([{ error: meta.touched && meta.error }]);

      const handleFieldBlur = () => form.setFieldTouched(name);

      return <UnitInput {...props} className={inputClassName} form={form} {...field} onBlur={handleFieldBlur} />;
    }}
  </Field>
);

import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { type AutocompleteProps, type AutocompleteRenderInputParams, ThemeProvider } from '@mui/material';
import AutocompleteDropdown from '@mui/material/Autocomplete';

import { makeGetCurrentTheme } from '@/store/interface';
import { THEME } from '@/styles/models';
import { Button } from '../button';
import { Icon } from '../icon';
import { AutocompleteInput } from './components/autocomplete-input';
import { autocompleteListStyle, autocompleteStyle } from './styles';

const DEBOUNCE_TIME = 250;
const DEFAULT_BUTTON_LABEL = 'button.assign';

const getCurrentTheme = makeGetCurrentTheme();

export type AutocompleteOption<T = string | number> = {
  value: T;
  label: string;
};

export type AutocompleteCustomProps<T = string | number> = Omit<
  AutocompleteProps<AutocompleteOption<T>, undefined, undefined, undefined>,
  'onChange' | 'renderInput' | 'multiple'
> & {
  buttonVisible?: boolean;
  buttonLabel?: string;
  onChange: (value: AutocompleteOption<T>, reason?: string) => void;
  form?: FormikProps<any>;
  name?: string;
  multiple?: boolean;
  hideValue?: boolean;
  initialValue?: string;
  onBlur?: () => void;
  noOptionPlaceholder?: boolean;
};

export type AutocompleteOnChangeFunc<T = string | number> = Required<AutocompleteCustomProps<T>>['onChange'];
export type AutocompleteOnInputChangeFunc = Required<AutocompleteCustomProps>['onInputChange'];
export type FilterOptionsFunc = Required<AutocompleteCustomProps>['filterOptions'];

export const Autocomplete = <T extends string | number = string | number>({
  options,
  placeholder,
  className,
  value,
  disabled,
  buttonLabel = DEFAULT_BUTTON_LABEL,
  onChange,
  onInputChange,
  filterOptions,
  buttonVisible,
  loading,
  form,
  name,
  disablePortal,
  hideValue,
  initialValue,
  noOptionPlaceholder,
  onBlur,
}: AutocompleteCustomProps<T>) => {
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption<T> | null | undefined>(value ? value : undefined);
  const [isInputDirty, setIsInputDirty] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const inputEvent$ = useRef(new Subject<Parameters<Required<AutocompleteCustomProps>['onInputChange']>>());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const closeDropdown = () => setIsDropdownOpen(false);
  const openDropdown = () => setIsDropdownOpen(true);
  const { themeName } = useSelector(getCurrentTheme);

  const isDarkMode = useMemo(() => themeName === THEME.DARK, [themeName]);

  useEffect(() => {
    const subscription = inputEvent$.current.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(value => {
      if (onInputChange !== undefined) {
        onInputChange(...value);
      }
    });

    return () => subscription.unsubscribe();
  }, [onInputChange]);

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleInputChange: Required<AutocompleteCustomProps>['onInputChange'] = useCallback(
    (event, value, reason) => {
      setIsInputDirty(value.length > 0 && value !== initialValue);

      if (value.length === 0) {
        closeDropdown();
      }

      return inputEvent$.current.next([event, value, reason]);
    },
    [closeDropdown]
  );

  const handleButtonClick = useCallback(
    e => {
      if (onChange !== undefined && selectedOption !== undefined && selectedOption !== null) {
        onChange(selectedOption);
        e.preventDefault();
        setSelectedOption(null);
      }
    },
    [onChange, selectedOption]
  );

  const handleSelectChange = useCallback(
    (_, option, reason) => {
      if (!form && reason === 'clear') {
        setSelectedOption(option);
        onChange(option, reason);
      }

      if (form && name) {
        form.setFieldValue(name, option);
      }

      if (option && option.value !== null) {
        setSelectedOption(option);
        if (!buttonVisible) {
          form ? onChange(option.value) : onChange(option);
        }
      }
    },
    [onChange, buttonVisible, form, name]
  );

  const renderInput = useCallback(
    (data: AutocompleteRenderInputParams) => (
      <AutocompleteInput {...data} isButtonVisible={buttonVisible} isDisabled={disabled} placeholder={placeholder} noOptionPlaceholder={noOptionPlaceholder} />
    ),
    [disabled, placeholder, buttonVisible, noOptionPlaceholder]
  );

  const handleInputBlur = useCallback(() => {
    onBlur?.();

    if (!selectedOption && options.length > 0) {
      setDropdownOptions([]);
    }
  }, [options, selectedOption, onBlur]);

  const [t] = useTranslation();

  return (
    <div css={autocompleteStyle} className={className}>
      <div className='autocomplete-wrapper'>
        <ThemeProvider theme={autocompleteListStyle}>
          <AutocompleteDropdown
            className='autocomplete'
            classes={{
              option: isDarkMode ? 'dark' : '',
              listbox: isDarkMode ? 'dark' : '',
              paper: isDarkMode ? 'dark' : '',
              popper: isDarkMode ? 'dark' : '',
              noOptions: isDarkMode ? 'dark' : '',
            }}
            options={dropdownOptions}
            onInputChange={handleInputChange}
            onChange={handleSelectChange}
            getOptionLabel={option => option.label}
            value={selectedOption && !hideValue ? selectedOption : null}
            loading={loading}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={renderInput}
            filterOptions={filterOptions}
            noOptionsText={isInputDirty ? t('label.noOptions') : t('label.startTyping')}
            loadingText={t('label.loading')}
            disabled={disabled}
            disablePortal={disablePortal}
            onBlur={handleInputBlur}
            popupIcon={<Icon name='mdi-chevron-down' />}
            open={isDropdownOpen}
            onOpen={openDropdown}
            onClose={closeDropdown}
          />
        </ThemeProvider>

        {buttonVisible && (
          <Button theme='secondary' className='autocomplete-button' onClick={handleButtonClick} disabled={disabled || isNil(selectedOption)}>
            {t(buttonLabel)}
          </Button>
        )}
      </div>
    </div>
  );
};

import { switchMap, filter } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getProviderClassificationsData } from '@/features/content/services/dictionaries';
import { dictionaryEffectHelper } from '@/common/utils';
import { providerClassificationsActions } from './actions';

const getProviderClassificationsList: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(providerClassificationsActions.request)),
    switchMap(() => {
      const state = state$.value.providerClassifications;
      return dictionaryEffectHelper(() => getProviderClassificationsData(), state, providerClassificationsActions);
    })
  );

export const providerClassificationsEpics = [getProviderClassificationsList];

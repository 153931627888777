import { ActionType, Action, createAction } from 'typesafe-actions';

import { createDictionaryActions, createFetchActions } from '@/common/utils/store';
import {
  type RatingContentTypeDictionaryVM,
  type ReviewApplicationsDictionaryVM,
  type ReviewDetailsRequest,
  type ReviewDetailsVM,
  type ReviewListRequest,
  type ReviewListSearchByOptionListVM,
  type ReviewListVM,
  type ReviewStatusDictionaryVM,
  type ReviewTargetTypesDictionaryVM,
} from '@/models/reviews';
import { type HistoryChangesListParams, type HistoryChangesListVM } from '@/models/history-changes';

export const reviewsActions = {
  list: createFetchActions('FETCH_REVIEWS_LIST_REQUEST', 'FETCH_REVIEWS_LIST_SUCCESS', 'FETCH_REVIEWS_LIST_FAILURE')<ReviewListRequest, ReviewListVM>(),
  clearReviewsList: createAction('CLEAR_REVIEWS_LIST')(),
  details: createFetchActions('FETCH_REVIEWS_DETAILS_REQUEST', 'FETCH_REVIEWS_DETAILS_SUCCESS', 'FETCH_REVIEWS_DETAILS_FAILURE')<
    ReviewDetailsRequest,
    ReviewDetailsVM
  >(),
  searchByOptions: createDictionaryActions(
    'FETCH_REVIEW_SEARCH_BY_OPTIONS_REQUEST',
    'FETCH_REVIEW_SEARCH_BY_OPTIONS_SUCCESS',
    'FETCH_REVIEW_SEARCH_BY_OPTIONS_FAILURE',
    'FETCH_REVIEW_SEARCH_BY_OPTIONS_CANCEL',
    'FETCH_REVIEW_SEARCH_BY_OPTIONS_SILENT_REQUEST'
  )<undefined, ReviewListSearchByOptionListVM>(),
  status: createDictionaryActions(
    'FETCH_REVIEW_STATUS_DICTIONARY_REQUEST',
    'FETCH_REVIEW_STATUS_DICTIONARY_SUCCESS',
    'FETCH_REVIEW_STATUS_DICTIONARY_FAILURE',
    'FETCH_REVIEW_STATUS_DICTIONARY_CANCEL',
    'FETCH_REVIEW_STATUS_DICTIONARY_SILENT_REQUEST'
  )<undefined, ReviewStatusDictionaryVM>(),
  ratingContentType: createDictionaryActions(
    'FETCH_RATING_CONTENT_TYPE_DICTIONARY_REQUEST',
    'FETCH_RATING_CONTENT_TYPE_DICTIONARY_SUCCESS',
    'FETCH_RATING_CONTENT_TYPE_DICTIONARY_FAILURE',
    'FETCH_RATING_CONTENT_TYPE_DICTIONARY_CANCEL',
    'FETCH_RATING_CONTENT_TYPE_DICTIONARY_SILENT_REQUEST'
  )<undefined, RatingContentTypeDictionaryVM>(),
  targetType: createDictionaryActions(
    'FETCH_REVIEW_TARGET_TYPE_DICTIONARY_REQUEST',
    'FETCH_REVIEW_TARGET_TYPE_DICTIONARY_SUCCESS',
    'FETCH_REVIEW_TARGET_TYPE_DICTIONARY_FAILURE',
    'FETCH_REVIEW_TARGET_TYPE_DICTIONARY_CANCEL',
    'FETCH_REVIEW_TARGET_TYPE_DICTIONARY_SILENT_REQUEST'
  )<undefined, ReviewTargetTypesDictionaryVM>(),
  application: createDictionaryActions(
    'FETCH_REVIEW_APPLICATION_DICTIONARY_REQUEST',
    'FETCH_REVIEW_APPLICATION_DICTIONARY_SUCCESS',
    'FETCH_REVIEW_APPLICATION_DICTIONARY_FAILURE',
    'FETCH_REVIEW_APPLICATION_DICTIONARY_CANCEL',
    'FETCH_REVIEW_APPLICATION_DICTIONARY_SILENT_REQUEST'
  )<undefined, ReviewApplicationsDictionaryVM>(),
  clearReviewDetails: createAction('CLEAR_REVIEW_DETAILS')(),
  historyChanges: createFetchActions(
    'FETCH_REVIEWS_HISTORY_CHANGES_LIST_REQUEST',
    'FETCH_REVIEWS_HISTORY_CHANGES_LIST_SUCCESS',
    'FETCH_REVIEWS_HISTORY_CHANGES_LIST_FAILURE'
  )<HistoryChangesListParams, HistoryChangesListVM>(),
  clearHistoryChanges: createAction('CLEAR_REVIEWS_HISTORY_CHANGES')(),
};

export type reviewsActions = Action | ActionType<typeof reviewsActions>;

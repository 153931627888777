import { ActionType, Action, createAction } from 'typesafe-actions';

import {
  type RoutesParams,
  type RoutesListVM,
  type RouteDetailsVM,
  type routeDetailsParams,
  type BulkChangeRoutesList,
  type RoutesFilters,
  type RouteThumbnailVM,
} from '@/features/content/models/routes';
import { type HistoryChangesListParams, type HistoryChangesListVM } from '@/models/history-changes';
import { type UploadStatus } from '@/common/components/upload-status-icon';
import { createFetchActions } from '@/common/utils/store';

export const routesActions = {
  list: createFetchActions('FETCH_ROUTES_REQUEST', 'FETCH_ROUTES_SUCCESS', 'FETCH_ROUTES_FAILURE')<RoutesParams, RoutesListVM>(),
  clearRoutesList: createAction('CLEAR_ROUTES_LIST')(),
  details: createFetchActions('FETCH_ROUTE_DETAILS_REQUEST', 'FETCH_ROUTE_DETAILS_SUCCESS', 'FETCH_ROUTE_DETAILS_FAILURE')<
    routeDetailsParams,
    RouteDetailsVM
  >(),
  bulkChangeList: createAction('ROUTES_BULK_CHANGE_LIST')<BulkChangeRoutesList>(),
  setRouteBulkChangeStatus: createAction('SET_ROUTE_BULK_CHANGE_STATUS')<{ routeId: string; status: UploadStatus; message?: string }>(),
  refreshRouteName: createAction('REFRESH_ROUTE_NAME')<{ routeId: string; name: string }>(),
  totalCount: createFetchActions('ROUTES_TOTAL_COUNT_REQUEST', 'ROUTES_TOTAL_COUNT_SUCCESS', 'ROUTES_TOTAL_COUNT_FAILURE')<RoutesFilters, number>(),
  thumbnail: createFetchActions('FETCH_ROUTE_THUMBNAIL_REQUEST', 'FETCH_ROUTE_THUMBNAIL_SUCCESS', 'FETCH_ROUTE_THUMBNAIL_FAILURE')<string, RouteThumbnailVM>(),
  clearRouteDetails: createAction('CLEAR_ROUTE_DETAILS')(),
  historyChanges: createFetchActions(
    'FETCH_ROUTES_HISTORY_CHANGES_LIST_REQUEST',
    'FETCH_ROUTES_HISTORY_CHANGES_LIST_SUCCESS',
    'FETCH_ROUTES_HISTORY_CHANGES_LIST_FAILURE'
  )<HistoryChangesListParams, HistoryChangesListVM>(),
  clearHistoryChanges: createAction('CLEAR_ROUTES_HISTORY_CHANGES')(),
};

export type routesActions = Action | ActionType<typeof routesActions>;

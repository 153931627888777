import React, { FC, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';

import { Dropdown, OpenButtonComponent } from '@/common/components/dropdown';
import { IconButton } from '@/common/components/icon-button';
import { Enum } from '@/common/utils';

const MoreOptionsItems = Enum('hideColumns');
const HORIZONTAL_OFFSET = -98;

export type OptionsHandlers = {
  onHideColumnDialogOpen?: () => void;
};

type Props = {
  isVisible: boolean;
  disabled?: boolean;
  onClickHandlers: OptionsHandlers;
};

export const OptionsDropdown: FC<Props> = ({ isVisible, disabled, onClickHandlers: { onHideColumnDialogOpen } }) => {
  const [t] = useTranslation();
  const ref = useRef<HTMLButtonElement>(null);

  const openButton = useCallback<OpenButtonComponent>(
    onClick => <IconButton iconName='mdi-more-vert-black' ref={ref} onClick={onClick} disabled={disabled} />,
    [disabled, ref]
  );

  const hideColumns = useMemo(
    () =>
      onHideColumnDialogOpen ? (
        <MenuItem id={MoreOptionsItems.hideColumns} key={MoreOptionsItems.hideColumns} onClick={onHideColumnDialogOpen} disableRipple>
          {t('label.changeColumns')}
        </MenuItem>
      ) : null,
    [onHideColumnDialogOpen]
  );

  return isVisible ? (
    <Dropdown menuProps={{ anchorOrigin: { horizontal: HORIZONTAL_OFFSET } }} openButton={openButton} openingRef={ref}>
      {[hideColumns]}
    </Dropdown>
  ) : null;
};

import dayjs from 'dayjs';

import { type ApiPagination, type PageableListResponse, DEFAULT_DATE_FORMAT, Enum } from '@/common/utils';

export type HistoryChangesTargetType = Enum<typeof HistoryChangesTargetType>;
export const HistoryChangesTargetType = Enum('USER', 'ORGANISATION', 'RATING', 'ROUTE');

export type HistoryChangesListParams = ApiPagination<{
  query: {
    targetType: HistoryChangesTargetType;
    targetId: string;
    nextPageToken?: string;
  };
  pageParams: {
    requestContinuation?: string;
  };
}>;

export type HistoryChangesListResponse = PageableListResponse<HistoryChangesListEntityResponse> & {
  hasNext: boolean;
  nextPageToken?: string;
};

export type Changes = {
  fieldName: string;
  change: {
    oldValue: string;
    newValue: string;
  };
};

export type HistoryChangesListEntityResponse = {
  id: string;
  actionTypeDisplayName: string;
  changes: Changes[];
  createdAt: string;
  createdBy: {
    userId: string;
    email: string;
    givenName: string;
    surname: string;
    organisationId: string;
  };
};

export interface HistoryChangesVM extends HistoryChangesListEntityResponse {
  author: {
    userId: string;
    name: string;
  };
  whatChanged: Changes[];
}
export class HistoryChangesVM {
  constructor({ changes, createdBy, createdAt, ...props }: HistoryChangesListEntityResponse) {
    Object.assign(this, {
      ...props,
      createdAt: createdAt ? dayjs(createdAt).format(DEFAULT_DATE_FORMAT) : '',
      author: {
        userId: createdBy.userId,
        name: createdBy.givenName && createdBy.surname ? `${createdBy.givenName} ${createdBy.surname}` : '',
      },
      whatChanged: changes || [],
    });
  }
}

export interface HistoryChangesListVM {
  list: HistoryChangesVM[];
  pagination?: {
    hasNext: boolean;
    nextPageToken?: string;
  };
}
export class HistoryChangesListVM {
  constructor({ content, hasNext, nextPageToken }: HistoryChangesListResponse) {
    this.list = (content || []).map(element => new HistoryChangesVM(element));
    this.pagination = {
      hasNext: hasNext || false,
      nextPageToken,
    };
  }
}

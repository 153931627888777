import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

const linkColor = '#0000EE';

export const textEditorStyles: StyleFunction = ({ theme }) => ({
  boxSizing: 'border-box',
  paddingTop: constants.offset.small,
  paddingBottom: constants.offset.small,
  paddingLeft: constants.offset.medium,
  paddingRight: constants.offset.medium,
  lineHeight: 1.5,
  width: '100%',
  backgroundColor: theme.backgroundNinth,
  color: theme.textPrimary,
  transitionProperty: 'border-color, box-shadow',
  transitionDuration: '.2s',
  resize: 'none',
  minHeight: '125px !important',
  borderRadius: constants.borderRadius.small,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,

  span: {
    color: theme.textPrimary,
  },

  div: {
    color: theme.textPrimary,
  },

  p: {
    color: theme.textPrimary,
  },

  '.chromium-helper': {
    fontSize: 0,
  },

  '&:not(.ck-focused)': {
    border: `1px solid ${theme.borderThird}`,
  },

  '&:focus': {
    border: `1px solid ${theme.borderPrimaryActive}`,
    boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
    outline: 'none',
  },

  '& > p': {
    color: theme.textPrimary,
  },

  '.element-link': {
    '& > a': {
      position: 'relative',
      color: linkColor,
    },

    'span:not(.link-popup, .url-value)': {
      color: linkColor,
    },
  },

  '.url-input': {
    minWidth: 170,
    height: 30,
  },

  '.link-popup': {
    display: 'flex',
    alignItems: 'center',
    padding: constants.offset.xsmall,
    border: `1px solid ${theme.borderThird}`,
    position: 'absolute',
    left: '50%',
    background: theme.backgroundPrimary,
    zIndex: 1,

    '.url-value': {
      padding: `0 ${constants.offset.small}px`,
      whiteSpace: 'nowrap',
    },

    '.icon-button': {
      padding: `0 ${constants.offset.xsmall}px`,
    },

    '.mdi-link-off': {
      transform: 'rotate(125deg)',
    },
  },
});

import { switchMap, filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';

import { getUserData, getUserDetailsData, getUsersAppsList } from '@/features/accounts/services/users';
import { untilLocationChange, CommonError, dictionaryEffectHelper } from '@/common/utils';
import { getHistoryChangesData } from '@/common/services';
import { getAppDictionaryData, getPlatformDictionaryData, getUserListSearchByOptionsData } from '@/features/accounts/services/dictionaries';
import { usersReducer } from './reducers';
import { usersActions } from './actions';

const getUsersEpic: Epic<usersActions, usersActions, usersReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(usersActions.list.request)),
    switchMap(() =>
      getUserData(state$.value.users.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return usersActions.list.failure(response);
          }

          return usersActions.list.success(response);
        })
      )
    )
  );

export const getHistoryChangesEpic: Epic<usersActions, usersActions, usersReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(usersActions.historyChanges.request)),
    switchMap(action =>
      getHistoryChangesData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return usersActions.historyChanges.failure(response);
          }

          return usersActions.historyChanges.success(response);
        })
      )
    )
  );

const getUserDetails: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(usersActions.details.request)),
    switchMap(action =>
      getUserDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return usersActions.details.failure(response);
            }

            return usersActions.details.success(response);
          })
        )
    )
  );

const getPopoverUser: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(usersActions.popover.request)),
    switchMap(action =>
      getUserDetailsData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return usersActions.popover.failure(response);
            }

            return usersActions.popover.success(response);
          })
        )
    )
  );

const getSearchByOptionList: Epic<usersActions, usersActions, usersReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(usersActions.searchByOptions.request)),
    switchMap(() => {
      const state = state$.value.users.searchByOptions;
      return dictionaryEffectHelper(() => getUserListSearchByOptionsData(), state, usersActions.searchByOptions);
    })
  ) as any;

const getUserApps: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(usersActions.usersApps.request)),
    switchMap(action =>
      getUsersAppsList(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return usersActions.usersApps.failure(response);
            }

            return usersActions.usersApps.success(response);
          })
        )
    )
  );

const getAppsList: Epic<usersActions, usersActions, usersReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(usersActions.appDictionary.request)),
    switchMap(() => {
      const state = state$.value.users.appDictionary;
      return dictionaryEffectHelper(() => getAppDictionaryData(), state, usersActions.appDictionary);
    })
  ) as any;

const getPlatformsList: Epic<usersActions, usersActions, usersReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(usersActions.platformDictionary.request)),
    switchMap(() => {
      const state = state$.value.users.platformDictionary;
      return dictionaryEffectHelper(() => getPlatformDictionaryData(), state, usersActions.platformDictionary);
    })
  ) as any;

export const usersEpics = [
  getUsersEpic,
  getUserDetails,
  getPopoverUser,
  getHistoryChangesEpic,
  getSearchByOptionList,
  getUserApps,
  getAppsList,
  getPlatformsList,
];

import { createReducer } from 'typesafe-actions';

import { ModalImagesList } from '@/models/gallery';
import { PAGE_SIZE } from '@/common/utils/pageable';
import { galleryActions } from '../actions';
import { FeatureStateType } from '../../helpers';

export type modalImagesState = {
  modalImagesList: ModalImagesList;
  canLoadPrevPage: boolean;
  canLoadNextPage: boolean;
  pageSize: number;
  pageIndex: number;
  isListLoading: boolean;
};

const INITIAL_STATE: modalImagesState = {
  modalImagesList: [],
  canLoadPrevPage: false,
  canLoadNextPage: false,
  pageSize: PAGE_SIZE[4].value,
  pageIndex: 0,
  isListLoading: false,
};

const reducer = createReducer<modalImagesState, galleryActions>(INITIAL_STATE)
  .handleAction(galleryActions.setModalImagesList, (state, { payload }) => ({
    ...state,
    modalImagesList: payload,
  }))
  .handleAction(galleryActions.setModalImagesListHasPrev, (state, { payload }) => ({
    ...state,
    canLoadPrevPage: payload,
  }))
  .handleAction(galleryActions.setModalImagesListHasNext, (state, { payload }) => ({
    ...state,
    canLoadNextPage: payload,
  }))
  .handleAction(galleryActions.setModalImagesListLoading, (state, { payload }) => ({
    ...state,
    isListLoading: payload,
  }))
  .handleAction(galleryActions.setModalImagesListReviewedStatus, (state, { payload }) => ({
    ...state,
    modalImagesList: state.modalImagesList.map(media =>
      media.mediaId === payload.mediaId ? { ...media, isReviewedStatus: payload.status, isReviewed: payload.isReviewed } : media
    ),
  }))
  .handleAction(galleryActions.setModalImagesListDeleteItem, (state, { payload }) => ({
    ...state,
    modalImagesList: state.modalImagesList.filter(media => media.mediaId !== payload),
  }))
  .handleAction(galleryActions.setModalImagesListDeleteStatus, (state, { payload }) => ({
    ...state,
    modalImagesList: state.modalImagesList.map(media => (media.mediaId === payload.mediaId ? { ...media, deleteStatus: payload.status } : media)),
  }))
  .handleAction(galleryActions.setModalImagesListPageSize, (state, { payload }) => ({
    ...state,
    pageSize: payload,
  }))
  .handleAction(galleryActions.setModalImagesListPageIndex, (state, { payload }) => ({
    ...state,
    pageIndex: payload,
  }));
export const modalImagesReducer = { modalImagesList: reducer };
export type modalImagesReducer = FeatureStateType<typeof modalImagesReducer>;

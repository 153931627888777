import { ReactElement } from 'react';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, Enum } from '@/common/utils';
import { ApiPagination, PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { StatusWrapperStatus } from '@/common/components/status-wrapper';
import { UploadStatus } from '@/common/components/upload-status-icon';
import { AutocompleteOption } from '@/common/components/autocomplete';
import { SelectOption } from '@/common/components/form-controls-deprecated';
import { getRouteDistanceInKilometers, getRouteAscentInMeters } from '../services/routes';
import { RouteTypeValues } from '../pages/routes-list/components/routes-list-filters';

export type RoutesUpdateType = Enum<typeof RoutesUpdateType>;
export const RoutesUpdateType = Enum('replace');

export const RoutesRecommended = Enum('APPROVED', 'APPROVAL_REQUIRED', 'NOT_APPROVED');
export type RoutesRecommended = Enum<typeof RoutesRecommended>;

export const OwnerType = Enum('AUTHOR', 'ORGANISATION');
export type OwnerType = Enum<typeof OwnerType>;

export type RoutesFilters = {
  currentRegionUrl?: string;
  types?: string[];
  difficulties?: string[];
  minDistance?: number;
  maxDistance?: number;
  minRating?: number | undefined;
  maxRating?: number | undefined;
  createdBy?: AutocompleteOption;
  organisationId?: SelectOption;
  visibilities?: string[];
  state?: string;
  recommended?: string[];
  includeWithoutRating?: boolean;
  id?: string;
  routeType?: RouteTypeValues[];
  searchType?: string;
  isOrganisational?: boolean;
  providerClassifications?: string[];
};

export type RoutesParams = ApiPagination<RoutesFilters>;

export type ContentProvidedByUserRoutesParams = Omit<RoutesParams, 'createdBy'> & {
  createdBy?: string;
};

export type routeDetailsParams = {
  id: string;
  currentRegionUrl: string | undefined;
};

export type RoutesEntityResponse = {
  id: string;
  deleted: boolean;
  name: string;
  type: string;
  difficulty: string;
  distance: number;
  created: string;
  updated: string;
  metadata: {
    recommended: ReactElement | string;
  };
  provider?: {
    id: string;
    name: string;
  };
  rating: Rating;
  organisation: string;
  visibility: string;
  activity: string;
  createdBy: Author;
  recommended: string;
  characteristics: {
    looped?: boolean;
  };
};

type Author = {
  displayName: string;
  email: string;
  userId: string;
};

export type RoutesListResponse = PageableListResponse<RoutesEntityResponse> & { hasNextPage: boolean };
export type RoutesListResponseTemp = {
  content: RoutesEntityResponse[];
  hasNextPage: boolean;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    sort: [
      {
        ascending: boolean;
        descending: boolean;
        direction: string;
        ignoreCase: boolean;
        nullHandling: string;
        property: string;
      }
    ];
  };
};

export type QualityScore = {
  name: string;
  rating: number;
  weight: number;
};

export interface RoutesElementVM extends RoutesEntityResponse {
  metadata: {
    name?: string;
    deleted: boolean;
    createdAt: string;
    modifiedAt: string;
    recommended: ReactElement | string;
    visibility: ReactElement | string;
    origin: {
      provider: {
        id: ReactElement | string;
      };
    };
    qualityRating?: {
      meetsCriteria: boolean;
      qualityThreshold: number;
      scores: QualityScore[];
      totalScore: number;
    };
    rating: Rating;
  };
  characteristics: {
    difficulty: string;
    distance: number;
    type: string;
    activity: string;
    author: ReactElement | string;
    authorId: string;
    looped?: boolean;
  };
}

export class RoutesElementVM {
  constructor({
    created,
    updated,
    provider,
    distance,
    name,
    difficulty,
    type,
    visibility,
    createdBy,
    rating,
    metadata,
    characteristics,
    ...props
  }: RoutesEntityResponse) {
    Object.assign(this, {
      ...props,
      metadata: {
        name,
        visibility,
        recommended: metadata.recommended && metadata.recommended === RoutesRecommended.APPROVED ? 'YES' : 'NO' || '',
        createdAt: created ? dayjs(created).format(DEFAULT_DATE_FORMAT) : '',
        modifiedAt: updated ? dayjs(updated).format(DEFAULT_DATE_FORMAT) : '',
        origin: {
          provider: {
            id: provider ? provider.id : '',
            name: provider ? provider.name : '',
          },
        },
        rating: {
          ...rating,
          average: rating.average.toFixed(2),
        },
      },
      characteristics: {
        difficulty,
        type,
        activity: type,
        distance: getRouteDistanceInKilometers(distance),
        author: createdBy ? createdBy.displayName : '',
        authorId: createdBy ? createdBy.userId : '',
        looped: characteristics.looped,
      },
    });
  }
}

export class RoutesListVM extends PageableListVM<RoutesElementVM, RoutesListResponse> {
  public hasNextPage: boolean;
  constructor(props: { VM: new (param: RoutesListResponse extends PageableListResponse<infer R> ? R : any) => RoutesElementVM; data: RoutesListResponse }) {
    super(props);
    this.hasNextPage = props.data.hasNextPage;
  }
}

type RouteProvider = {
  id: string;
  legacyPremiumProvider: boolean;
  name: string;
};

type RouteOriginUser = {
  displayName: string;
  email: string;
  userId: string;
  organisationId?: string;
};

type RouteOrigin = {
  createdBy: RouteOriginUser;
  originalId: string;
  provider: RouteProvider | null;
};

export type Rating = {
  average: number;
  count: number;
};

export type StatsSummary = {
  follows: number;
  views: number;
};

export const RouteStatsPlatform = Enum('OSMAPS_ANDROID', 'OSMAPS_IOS', 'OSMAPS_WEB');
export type RouteStatsPlatform = Enum<typeof RouteStatsPlatform>;

type TotalStats = {
  statsPerApp: Record<RouteStatsPlatform, StatsSummary>;
  totalStats: StatsSummary;
};

type RouteMetadata = {
  visibility: string;
  name: string;
  description?: string;
  premium: boolean;
  modifiedAt: string;
  createdAt: string;
  origin: RouteOrigin;
  rating: Rating;
  recommended: string;
  deleted: boolean;
  qualityRating?: {
    meetsCriteria: boolean;
    qualityThreshold: number;
    scores: QualityScore[];
    totalScore: number;
  };
};

type RouteCharacteristics = {
  distance: number;
  elevationAscent: number;
  equivalentDistance: number;
  activity: string;
  surfaceTypes: string[];
  difficulty: string;
  // TODO: rename if nesesery when BE ready
  region: string;
  looped: boolean;
};

export type RouteDetailsResponse = {
  metadata: RouteMetadata;
  characteristics: RouteCharacteristics;
  stats?: TotalStats;
};

export interface RouteDetailsVM extends RouteDetailsResponse {}

export class RouteDetailsVM {
  constructor({ characteristics, ...props }: RouteDetailsResponse) {
    Object.assign(this, {
      ...props,
      metadata: {
        ...props.metadata,
        createdAt: props.metadata.createdAt ? dayjs(props.metadata.createdAt).format(DEFAULT_DATE_FORMAT) : '',
      },
      characteristics: {
        ...characteristics,
        distance: getRouteDistanceInKilometers(characteristics.distance),
        elevationAscent: getRouteAscentInMeters(characteristics.elevationAscent),
      },
    });
  }
}

export type operationEnum = Enum<typeof operationEnum>;
export const operationEnum = Enum('replace', 'add');

export type patchEnum = Enum<typeof patchEnum>;
export const patchEnum = Enum(
  '/characteristics/activity',
  '/characteristics/difficulty',
  '/metadata/description',
  '/metadata/name',
  '/metadata/visibility',
  '/metadata/recommended'
);

export type RoutesUpdateParams = {
  op: operationEnum;
  path: patchEnum;
  value: string | undefined;
};

export type RoutesUpdateParamsList = RoutesUpdateParams[];

type UserRoutesEntityResponse = {
  created: string;
  difficulty: string;
  distance: number;
  fitness: string;
  id: string;
  name: string;
  provider: {
    id: string;
    legacyPremiumProvider: boolean;
    name: string;
    osMapsLegacyId: number;
  };
  type: string;
  updated: string;
  visibility: string;
};

export type UserRoutesListResponse = UserRoutesEntityResponse[];

export interface UserRoutesElementVM {
  name: string;
  updated: string;
  id: string;
}

export class UserRoutesElementVM {
  constructor({ name, updated, id }: UserRoutesEntityResponse) {
    this.name = name;
    this.id = id;
    this.updated = updated ? dayjs(updated).format(DEFAULT_DATE_FORMAT) : '';
  }
}

export interface UserRoutesListVM {
  list: UserRoutesElementVM[];
  status: StatusWrapperStatus;
}

export class UserRoutesListVM {
  constructor(list: UserRoutesListResponse) {
    this.list = (list || []).map(element => new UserRoutesElementVM(element));
  }
}

export type BulkChangeRouteElement = {
  id: string;
  name: string | undefined;
  status: UploadStatus;
  errorMessage?: string;
};

export type BulkChangeRoutesList = BulkChangeRouteElement[];

export type RouteThumbnailResponse = {
  url: string;
  height: number;
  width: number;
  modifiedDate: string;
};

export interface RouteThumbnailVM extends RouteThumbnailResponse {}

export class RouteThumbnailVM {
  constructor(props: RouteThumbnailResponse) {
    Object.assign(this, props);
  }
}

export type RoutesTotalCountResponse = {
  totalRoutes: number;
};
